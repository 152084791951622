var render = function render(){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"font-weight-light text-truncate",class:{
	'text-center': _vm.centered,
	'accent--text': !_vm.color,
	'display-2': _vm.large && !_vm.small,
	title: !_vm.large && !_vm.small,
	'font-weight-medium': _vm.bold,
	'subtitle-1': _vm.small,
},style:({color: _vm.color, 'line-height': '1.4 !important'})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }