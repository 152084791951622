const defaultSupplier = {
	Hostname:                'lon.crona.se',
	WebPageTitle:            'Crona Lön',
	SoftwareName:            'Crona Lön',
	Supplier:                'CRONA',
	AssetsFolder:            'crona',
	NewsTag:                 'WEBLON',
	CalendarTag:             'WEBLON',
	ContactNumber:           '010-222 70 00',
	ContactEmail:            'order@cronalon.se',
	AllowRequestDemoAccount: true,
};

const edison = {
	Hostname:                'lon.edison.se',
	WebPageTitle:            'Edison Lön',
	SoftwareName:            'Edison Lön',
	Supplier:                'EDISON',
	AssetsFolder:            'edison',
	NewsTag:                 'WEBEDL',
	CalendarTag:             'WEBEDL',
	ContactNumber:           '',
	ContactEmail:            '',
	AllowRequestDemoAccount: false,
};

const acconomy = {
	Hostname:                'lon.acconomy.com',
	WebPageTitle:            'Acconomy Lön',
	SoftwareName:            'Acconomy Lön',
	Supplier:                'ACCONOMY',
	AssetsFolder:            'acconomy',
	NewsTag:                 'ACCONOMY',
	CalendarTag:             'ACCONOMY',
	ContactNumber:           '',
	ContactEmail:            '',
	AllowRequestDemoAccount: false,
};

const suppliers = [
	edison,
	acconomy,
	defaultSupplier,
];

export default {
	fromHostname(hostname = '') {
		for(const item of suppliers) {
			if(item.Hostname === hostname.toLowerCase()) {
				return item;
			}
		}

		return defaultSupplier;
	},

	fromSupplier(supplier = '') {
		for(const item of suppliers) {
			if(item.Supplier === supplier.toUpperCase()) {
				return item;
			}
		}

		return defaultSupplier;
	},
};
