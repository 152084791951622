/**
 * Created by simon on 2018-11-14.
 */

import axios        from 'axios';
import Vue          from 'vue';
import _            from 'lodash';
import BaseModel    from '../models/BaseModel.js';
import Store        from '../store/index.js';
import LocalStorage from './LocalStorage.js';

const APIURL = process.env.VUE_APP_API_URI;
const isDev  = process.env.VUE_APP_UTV === 'true';

const httpClient = axios
	.create({
		baseURL: APIURL,
		headers: {
			'DV-API-REQUEST': true,
		},
		withCredentials: isDev,
	});

httpClient.interceptors.response.use((response) => {
	if(response.headers && response.headers['x-build-id']) {
		Vue.prototype.$eventBus.$emit('WebSocketClient:UpdateBuildID', response.headers['x-build-id']);
	}

	return response;
});

class BackendError extends Error {
	constructor(message, dvCode) {
		super(message);

		this.name = 'BackendError';
		this.DVCode = dvCode;

		this.isBackendError = true;
	}
}

const handleError = (error, options = {}) => {
	if(Store.state.NetworkOffline === true) {
		Store.dispatch('setSnackbar', {
			text:  `Störningar har upptäckts i din internetanslutning. Kontrollera anslutningen och försök igen.`,
			color: 'error',
		});

		return;
	}

	const isAuthenticated = Store.state.Authenticated;
	const isSessionDestroyed = isAuthenticated &&
		(
			(_.get(error, 'response.status', null) === 403 && _.toString(_.get(error, 'response.data.dvCode', null)).slice(0, 3) !== '403') ||
			(_.get(error, 'response.status', null) === 401)
		);

	if(isSessionDestroyed) {
		Store.dispatch('setSnackbar', {
			text:  `Din session i programmet har upphört, vänligen logga in på nytt.`,
			color: 'error',
		});
		Store.dispatch('logout', {saveSnackbarState: true});

		return;
	}

	//Overwrite message with backend message if it exists
	const backendMessage = _.get(error, 'response.data.message', null);

	if(backendMessage) {
		if(!options.skipBackendMessageSnackbar) {
			Store.dispatch('setSnackbar', {
				text:  backendMessage,
				color: 'error',
			});
		}

		throw new BackendError(backendMessage, error.response.data.dvCode);
	}

	//Network error
	if((!error.response || error.message === 'Network Error') && error.config && _.last(error.config.url.split('/')) !== 'me') {
		Store.dispatch('setSnackbar', {
			text:  `Störningar har upptäckts i nätverkskommunikationen. Kontrollera din internetanslutning och försök igen.`,
			color: 'error',
		});

		return;
	}

	throw error;
};

const setPaginationInfo = (response) => {
	const {data, headers = {}} = response;

	if(headers && headers['x-pagination-info']) {
		data.PaginationInfo = JSON.parse(headers['x-pagination-info']);
	}
};

class HttpRequester {
	static get BackendError() {
		return BackendError;
	}

	static async get(url, options = {}) {
		try {
			const response = await httpClient.get(url, appendSelectionHeaders(options));

			setPaginationInfo(response);

			return response.data;
		} catch(error) {
			return handleError(error, options);
		}
	}

	static async post(url, data, options = {}) {
		try {
			const response = await httpClient.post(url, getData(data), appendSelectionHeaders(options));

			setPaginationInfo(response);

			return response.data;
		} catch(error) {
			return handleError(error, options);
		}
	}

	static async put(url, data, options = {}) {
		try {
			const response = await httpClient.put(url, getData(data), appendSelectionHeaders(options));

			setPaginationInfo(response);

			return response.data;
		} catch(error) {
			return handleError(error, options);
		}
	}

	static async delete(url, options) {
		try {
			const response = await httpClient.delete(url, appendSelectionHeaders(options));

			setPaginationInfo(response);

			return response.data;
		} catch(error) {
			return handleError(error, options);
		}
	}
}

export default HttpRequester;

function getData(data) {
	if(data instanceof BaseModel) {
		return data.toJSON();
	} else if(_.isArray(data)) {
		return _.map(data, (item) => getData(item));
	} else if(_.isPlainObject(data)) {
		const keys = _.keys(data);

		for(const key of keys) {
			data[key] = getData(data[key]);
		}
	}

	return data;
}

function appendSelectionHeaders(options = {}) {
	options.headers = options.headers || {};

	if(!_.has(options.headers, 'dv-selected-company')) {
		options.headers['dv-selected-company'] = Store.state.FtgInfo && Store.state.FtgInfo.FtgId ? Store.state.FtgInfo.FtgId : '';
	}

	if(!_.has(options.headers, 'dv-selected-year')) {
		options.headers['dv-selected-year'] = Store.state.SalaryYear || '';
	}

	options.headers['dv-inactivity-timer'] = LocalStorage.get('InactivityTimer', 60);

	_.forEach(options.headers, (value, key) => {
		if(_.isNil(value)) {
			delete options.headers[key];
		}
	});

	return options;
}
