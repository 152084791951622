<template>
	<div>
		<CompanyRestoreDialog v-if="companyDumpRestoreEnabled" ref="companyRestoreDialog" @restored="onCompanyRestored"/>

		<Dialog
				v-bind="$attrs"
				v-model="dialog"
				:width="700"
				persistent
				ref="dialog"
				:use-card-padding="false"
				no-padding
				@click:outside="onClickOutside"
				@show="onShow"
		>
			<v-app-bar dark color="primary" dense>
				<v-toolbar-title>
					<v-img
							:src="$buildBrandedAssetsURL('/logos/white_long.png')"
							:lazy-src="$buildBrandedAssetsURL('/logos/white_long.png')"
							contain
							width="125"
					/>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn small text class="no-hover pa-0 mr-2" :ripple="false">{{UserAccount.Namn}}</v-btn>
				<Tooltip text="Logga ut">
					<v-btn icon @click="onLogout" small><v-icon>mdi-logout</v-icon></v-btn>
				</Tooltip>
			</v-app-bar>
			<v-tabs
					v-if="!isLoading"
					v-model="selectedTab"
					:color="$vuetify.theme.dark ? 'accent' : 'primary'"
					slider-color="accent"
					grow
					card
			>
				<v-tab
						:key="0"
						ripple
						:disabled="companyList.length === 0"
				>
					Välj företag
				</v-tab>
				<v-tab-item
						:key="0"
				>
					<v-divider></v-divider>
					<v-card flat>

						<v-card-text>
							<template v-if="companyDumpRestoreEnabled">
								<v-row no-gutters class="text-center">
									<v-col cols="12">
										<v-btn depressed color="accent" class="ml-5 mr-5 mb-5" @click="$refs.companyRestoreDialog.show()"><v-icon left>mdi-database-eye</v-icon> Läs in kopia av företag</v-btn>
									</v-col>
								</v-row>
							</template>

							<SimpleTextInput
									v-if="companyList.length >= 10"
									autofocus
									name="SearchTermCompanyList"
									ref="SearchTermCompanyList"
									v-model="searchTermCompanyList"
									append-icon="mdi-magnify"
									placeholder="Sök namn, orgnr, id.."
									hide-details
									clearable
									class="mr-5 ml-5"
							/>

							<v-slide-x-transition group hide-on-leave>
								<SelectCompanyList
										:key="0"
										v-if="!isLoading && companyListFavourites.length > 0"
										:items="companyListFavourites"
										title="Favoriter"
										@click="selectCompany"
										@reload="loadCompanyList"
								/>

								<SelectCompanyList
										:key="1"
										v-if="!isLoading && companyListOthers.length > 0"
										:items="companyListOthers"
										:title="companyListFavourites.length > 0 ? 'Övriga' : ''"
										@click="selectCompany"
										@reload="loadCompanyList"
								/>
							</v-slide-x-transition>
						</v-card-text>
					</v-card>
				</v-tab-item>


				<template v-if="['LIC', 'ADM'].includes(UserAccount.Roll)">
					<v-tab
							:key="1"
							ripple
					>
						Skapa företag
					</v-tab>
					<v-tab-item
							:key="1"
					>
						<v-divider></v-divider>
						<v-card flat>
							<v-card-text>
								<v-form ref="form">
									<InputField :model="ftgInfo" @change="onChangeFtgId" autofocus name="FtgId" ref="FtgId" :counter="15" :disabled="isSavingNewCompany"/>
									<InputField :model="ftgInfo" name="Namn" ref="Namn" :counter="40" :disabled="isSavingNewCompany"/>
									<InfiniteSelect
											v-model="Year"
											ref="Year"
											name="Year"
											label="Startår"
											:disabled="isSavingNewCompany"
											:items="YearList"
									/>
									<InfiniteSelect
											v-model="LoneartsTabell"
											ref="LoneartsTabell"
											name="LoneartsTabell"
											label="Löneartstabell"
											:disabled="isSavingNewCompany"
											:items="LoneartsTabeller"
											item-value="TabellKod" item-text="BenText"
									/>
									<SimpleCheckbox v-if="StdTestDataEnabled" v-model="StdTestData" label="Skapa testpersoner" persistent-hint hint="Prorammet skapar ett par exempelpersoner som du kan använda för att testa med"/>
									<SimpleCheckbox v-if="IncludeCSRegistriesEnabled" v-model="IncludeCSRegistries" label="Skapa standardregister för beräkningsregler" persistent-hint hint="Exempelvis OB, Jour, Beredskap, Övertid"/>
									<SimpleCheckbox v-if="ShowInactivatePortalHRFunctions" v-model="InactivatePortalHRFunctions" label="Inaktivera HR-funktioner i Crona Tid & Resa" persistent-hint hint="Funktionerna kan alltid aktiveras i efterhand"/>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-btn v-if="companyList.length > 0" color="secondary" ref="CancelBtn" @click="$emit('cancel')">Avbryt</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" ref="SaveBtn" @click="onSave" :loading="isSavingNewCompany">Skapa</v-btn>
							</v-card-actions>
						</v-card>
					</v-tab-item>

					<template v-if="companyListArchived.length > 0 && ['LIC', 'ADM'].includes(UserAccount.Roll)">
						<v-tab
							:key="2"
							ripple
						>
							Arkiv
						</v-tab>

						<v-tab-item :key="2">
							<v-divider></v-divider>
							<SelectCompanyList
								:key="1"
								v-if="!isLoading && companyListArchived.length > 0"
								:items="companyListArchived"
								:title="''"
								:show-favourite="false"
								@click="selectCompany"
								@reload="loadCompanyList"
							/>
						</v-tab-item>
					</template>
				</template>
			</v-tabs>

			<v-card flat v-if="isLoading">
				<v-card-text>
					<Loading :visible="true"/>
				</v-card-text>
			</v-card>
		</Dialog>
	</div>
</template>

<script>

import _                      from 'lodash';
import {mapState, mapActions} from 'vuex';
import moment                 from 'moment';
import FtgInfo                from '../models/FtgInfo.js';
import LocalStorage           from '../lib/LocalStorage.js';
import Loading                from './Loading.vue';
import InputField             from './form/InputField.vue';
import SelectCompanyList      from './SelectCompanyList.vue';
import Dialog                 from './Dialog.vue';
import Tooltip                from './Tooltip.vue';
import InfiniteSelect         from './InfiniteSelect.vue';
import SimpleTextInput        from './SimpleTextInput.vue';
import CompanyRestoreDialog   from './CompanyRestoreDialog.vue';
import SimpleCheckbox         from './SimpleCheckbox.vue';

export default {
	name: 'SelectCompany',

	props: {
		showOnMounted: {
			type:    Boolean,
			default: true,
		},
	},

	data: () => ({
		tabOrder: [
			'FtgId',
			'Namn',
			'Year',
			'LoneartsTabell',
			'StdTestData',
			'SaveBtn',
		],
		dialog:             true,
		isLoading:          true,
		isSavingNewCompany: false,
		companyList:        [],
		selectedTab:        0,
		ftgInfo:            new FtgInfo({
			Namn:  '',
			Orgnr: '',
		}),
		LoneartsTabell:   'STANDARD',
		LoneartsTabeller: [
			{TabellKod: 'STANDARD', BenText: 'Standardtabell'},
			{TabellKod: 'HANDELS', BenText: 'Handels'},
			{TabellKod: 'LSS AUTO', BenText: 'LSS Standard (automatiska val)'},
			{TabellKod: 'TRANSP', BenText: 'Transport'},
		],
		StdTestData:                 false,
		IncludeCSRegistries:         false,
		InactivatePortalHRFunctions: false,
		Year:                        moment().year(),
		searchTermCompanyList:       '',
	}),

	computed: {
		...mapState(['UserAccount', 'FtgInfo', 'HasLicensOption', 'HasUserOption']),

		ShowInactivatePortalHRFunctions() {
			return this.HasLicensOption.HRTIL && this.HasUserOption.HRTIL && (this.HasLicensOption.PORMAX || this.HasLicensOption.PORTAL || this.HasLicensOption.PORBAS)
		},

		StdTestDataEnabled() {
			return process.env.VUE_APP_DEBUG === 'true' || [
				'WEB999915',
				'WEB999998',
			].includes(this.UserAccount.LicNr);
		},

		IncludeCSRegistriesEnabled() {
			return process.env.VUE_APP_DEBUG === 'true' || [
				'WEB999400',
				'WEB999350',
			].includes(this.UserAccount.LicNr);
		},

		companyListFavourites() {
			return this.filterAgainstSearchTermCompany(_.sortBy(_.filter(this.companyList, {isFavourite: true, Archived: false}), ['Namn']));
		},

		companyListOthers() {
			return this.filterAgainstSearchTermCompany(_.sortBy(_.filter(this.companyList, (item) => !item.isFavourite && !item.Archived), 'Namn'));
		},

		companyListArchived() {
			return this.filterAgainstSearchTermCompany(_.sortBy(_.filter(this.companyList, {Archived: true}), ['Namn']));
		},

		YearList() {
			const {VUE_APP_FIRST_YEAR} = process.env;
			let currentYear = moment().year();

			const years = [];

			while(currentYear >= _.toNumber(VUE_APP_FIRST_YEAR)) {
				years.push({
					value: currentYear,
					text:  currentYear,
				});
				currentYear--;
			}

			return years;
		},
	},

	mounted() {
		if(this.showOnMounted) {
			this.show();
		}

		if(this.UserAccount.LicNr === 'WEB999400') {
			this.IncludeCSRegistries = true;
			this.InactivatePortalHRFunctions = true;
		}
	},

	methods: {
		...mapActions(['setAuthenticated', 'confirmDialog', 'logout']),

		onCompanyRestored(FtgId) {
			this.selectCompany({FtgId});
		},

		filterAgainstSearchTermCompany(items) {
			this.searchTermCompanyList = this.searchTermCompanyList || '';

			if(!this.searchTermCompanyList.trim()) {
				return items;
			}

			const searchTerm = this.searchTermCompanyList.trim().toLowerCase();

			return items
				.filter(({Namn, Orgnr, FtgId}) =>
					Namn.toLowerCase().includes(searchTerm) ||
								Orgnr.toLowerCase().includes(searchTerm) ||
								FtgId.toLowerCase().includes(searchTerm)
				);
		},

		onLogout() {
			this.logout();
		},

		show() {
			this.$refs.dialog.show();
		},

		hide() {
			this.$refs.dialog.hide();
		},

		onShow() {
			if(this.UserAccount.Roll === 'FTG' && this.UserAccount.FtgIdAccessList.filter((value) => value !== this.UserAccount.FtgId).length === 0) {
				this.selectCompany({FtgId: this.UserAccount.FtgId});
			} else {
				this.loadCompanyList();
			}
		},

		onClickOutside() {
			if(this.FtgInfo.FtgId) {
				this.$refs.dialog.hide();
			}
		},

		onChangeFtgId() {
			this.ftgInfo.FtgId = (this.ftgInfo.FtgId || '').replace(/[^\d+a-zäåö]/gi, '').replace(/å|ä/gi, 'a').replace(/ö/gi, 'o').toLowerCase();
		},

		loadCompanyList() {
			return this.$tryCatch({
				task: async () => {
					this.isLoading = true;

					const favourites = LocalStorage.get('FavouriteCompanies') || [];

					const companyList = await this.$http.get('/companyList');

					for(const company of companyList) {
						const {FtgId} = company;

						company.isFavourite = favourites.includes(FtgId);
					}

					this.companyList = companyList;

					this.selectedTab = this.companyList.length === 0 ? 1 : 0;
				},

				finally: () => {
					this.isLoading = false;
				},
			});
		},

		selectCompany(item) {
			//Står redan på detta bolag, behöver inte ladda om något.
			if(this.FtgInfo && this.FtgInfo.FtgId === item.FtgId) {
				this.$emit('cancel');

				return;
			}

			return this.$tryCatch({
				task: async () => {
					this.isLoading = true;

					const FtgInfo = await this.$http.get(`/companyInfo/${item.FtgId}`);

					if(!FtgInfo) {
						return;
					}

					const SalaryYears = await this.$http.get('/salaryyears', {
						headers: {
							'dv-selected-company': item.FtgId,
						},
					});

					const emitChange = () => {
						this.setAuthenticated({UserAccount: this.UserAccount, FtgInfo, SalaryYears});
						this.$emit('change', FtgInfo);
					};

					if(Object.keys(this.$route.params).length > 0) {
						this.$router.push({name: 'Infopanel', params: {skipUnsavedChanges: true}}, emitChange, emitChange);
					} else {
						emitChange();
					}
				},

				finally: () => {
					this.isLoading = false;
				},
			});
		},

		onSave() {
			if(!this.$validateForm() || this.isSavingNewCompany) {
				return;
			}

			if(this.companyList.find((item) => item.FtgId === this.ftgInfo.FtgId)) {
				this.$msgError('Det finns redan ett företag med angivet Företags-ID. Vänligen välj ett nytt.');

				return;
			}

			return this.$tryCatch({
				task: async () => {
					this.isSavingNewCompany = true;

					const FtgInfo = await this.$http.post('/createCompany', {
						Namn:                        this.ftgInfo.Namn,
						FtgId:                       this.ftgInfo.FtgId,
						LoneartsTabell:              this.LoneartsTabell,
						LoneartsTabellNamn:          this.LoneartsTabeller.find((item) => item.TabellKod === this.LoneartsTabell).BenText,
						Year:                        this.Year,
						StdTestData:                 this.StdTestData,
						IncludeCSRegistries:         this.IncludeCSRegistries,
						InactivatePortalHRFunctions: this.InactivatePortalHRFunctions,
					});

					const SalaryYears = await this.$http.get('/salaryyears', {
						headers: {
							'dv-selected-company': FtgInfo.FtgId,
						},
					});

					this.setAuthenticated({UserAccount: this.UserAccount, FtgInfo, SalaryYears});
					this.$emit('change', FtgInfo);

					this.$router.push({name: 'CompanySettings'}, _.noop, _.noop);
					this.$msgSuccess(`Företaget skapades. Vi rekommenderar att du börjar med att fylla på med uppgifter i företagsinställningarna.`)
				},

				finally: () => {
					this.isSavingNewCompany = false;
				},
			});
		},
	},

	components: {
		SimpleCheckbox,
		CompanyRestoreDialog,
		SimpleTextInput,
		InfiniteSelect,
		Tooltip,
		Dialog,
		Loading,
		InputField,
		SelectCompanyList,
	},
}
</script>

<style scoped>
	::v-deep(.no-hover) {
		cursor: auto !important;
	}
	::v-deep(.no-hover:hover::before) {
		opacity: 0 !important;
	}
</style>
