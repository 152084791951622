/**
 * Created by simon on 2019-12-17.
 */

import _ from 'lodash';

export default (value) => {
	const sum = [
		...value.slice(2),
	]
		.reverse()
		.map(_.toNumber)
		.map((number, index) => {
			if(index % 2) {
				return number * 2
			}

			return number;
		})
		.map((number) => {
			if(number > 9) {
				return number - 9;
			}

			return number;
		})
		.reduce((sum, number) => sum + number, 0);

	return sum % 10 === 0;
};
