/**
 * Created by simon on 2018-12-13.
 */

import AsyncTryCatch from '../lib/AsyncTryCatch.js';

const TryCatch = {
	install(Vue) {
		Vue.mixin({
			methods: {
				$tryCatch(task, errorHandler, finallyHandler) {
					return AsyncTryCatch(task, errorHandler, finallyHandler);
				},
			},
		});
	},
};

export default TryCatch;
