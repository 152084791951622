<template>
	<Dialog ref="dialog" :title="title" :persistent="isSaving || isDeleting">
		<v-lazy>
			<v-row dense justify="center">
				<v-col cols="12">
					<slot name="text"></slot>
				</v-col>
				<v-col cols="12">
					<FileUpload ref="fileUpload" v-model="file" @change="validateFile">
						<v-btn slot="activator" depressed color="accent" x-large outlined><v-icon left>mdi-camera-plus</v-icon> Ladda upp ny bild</v-btn>
					</FileUpload>
				</v-col>

				<v-col cols="12" v-if="validFile">
					<Cropper
									style="float: left;"
									ref="cropper"
									:src="file.srcDataContent"
									:stencil-component="useCircleStencil ? $options.components.CircleStencil : void 0"
									:maxWidth="maxWidth"
									:maxHeight="maxHeight"
									:defaultSize="({imageSize: {width, height}}) => ({width, height})"
					/>
<!--					To keep eslint happy..-->
					<CircleStencil v-if="false"/>
				</v-col>

			</v-row>

		</v-lazy>

		<template v-slot:buttons="{hide}">
			<v-btn :disabled="isSaving || isDeleting" color="secondary" @click="hide">Avbryt</v-btn>
			<v-spacer></v-spacer>
			<v-btn :loading="isDeleting" :disabled="isSaving" color="error" @click="onDelete" v-if="item[idField] !== '00000000-0000-0000-0000-000000000000'">Ta bort</v-btn>
			<v-btn :loading="isSaving" :disabled="isDeleting || !file" color="primary" @click="onSave">Spara</v-btn>
		</template>
	</Dialog>
</template>

<script>
import {
	Cropper,
	CircleStencil
} from 'vue-advanced-cropper';
import Dialog     from './Dialog.vue';
import FileUpload from './form/FileUpload.vue';


import 'vue-advanced-cropper/dist/style.css';

export default {
	name: 'PictureEditDialog',

	props: {
		title: {
			type:     String,
			required: true,
		},
		item: {
			type:     Object,
			required: true,
		},
		idField: {
			type:    String,
			default: 'ProfilePictureId',
		},
		saveHandler: {
			type:     Function,
			required: true,
		},
		deleteHandler: {
			type:     Function,
			required: true,
		},

		maxWidth: {
			type: Number,
		},

		maxHeight: {
			type: Number,
		},

		useCircleStencil: {
			type:    Boolean,
			default: false,
		},
	},

	data: () => ({
		file:       null,
		isSaving:   false,
		isDeleting: false,
	}),

	methods: {
		show() {
			this.$refs.dialog.show();
		},

		onSave() {
			this.$tryCatch({
				task: async () => {
					this.isSaving = true;

					const {canvas} = await this.$refs.cropper.getResult();

					await this.saveHandler({
						Content: canvas.toDataURL().split(';base64,')[1],
						Type:    this.file.type,
					}, this.item);

					this.$msgSuccess('Bilden har sparats.');

					this.$refs.fileUpload.reset();

					this.$refs.dialog.hide();
				},

				finally: () => {
					this.isSaving = false;
				},
			});
		},

		onDelete() {
			this.$tryCatch({
				task: async () => {
					this.isDeleting = true;

					await this.deleteHandler(this.item);

					this.$msgSuccess('Bilden togs bort.');

					this.$refs.dialog.hide();
				},

				finally: () => {
					this.isDeleting = false;
				},
			});
		},

		validateFile(file) {
			if(file && file.extension) {
				if(!this.validExtension(file.extension)) {
					this.$refs.fileUpload.reset();
					this.$msgError('Felaktig fil. Välj en bild med filformatet PNG eller JPEG.');
				}

				if(file.size > 5_000_000) {
					this.$refs.fileUpload.reset();
					this.$msgError('Bilden är för stor. Välj en bild som inte överstiger 5mb.');
				}
			}
		},

		validExtension(extension) {
			return ['jpeg', 'png', 'jpg', 'jfif'].includes(extension.toLowerCase());
		},
	},

	computed: {
		validFile() {
			return this.file && this.file.srcDataContent && this.validExtension(this.file.extension);
		},
	},

	components: {
		FileUpload,
		Dialog,
		Cropper,
		CircleStencil,
	},
}
</script>

<style scoped>

</style>
