<template>
	<v-dialog
					v-model="visible"
					v-bind="$attrs"
					v-on="$listeners"
					:width="width"
					:fullscreen="fullscreen"
					:persistent="fullscreen || $attrs.persistent"
					:no-click-animation="fullscreen || $attrs['no-click-animation']"
					:retain-focus="false"
					ref="dialog"
	>
		<template v-slot:activator="{on}">
			<slot name="activator" v-bind="{on}"></slot>
		</template>

		<v-card :disabled="disabled" :loading="loading">
			<template v-if="fullscreen">
				<v-toolbar color="primary" flat dense dark>
					<v-toolbar-title>{{title}}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn :disabled="$attrs.persistent === true" icon dark @click="hide">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
			</template>

			<template v-else>
				<v-card-title v-if="title">
					<template v-if="tooltipIconRight || titleIconRight">
						<v-row no-gutters>
							<v-col cols="10">
								<Title :centered="centeredTitle">
									{{title}}
								</Title>
							</v-col>
							<v-col cols="2" class="text-right">
								<Tooltip v-if="tooltipIconRight && titleIconRight" :text="tooltipIconRight">
									<v-btn v-if="titleIconRight" small icon  @click="$emit('clickTitleIconRight', $event)"><v-icon>{{titleIconRight}}</v-icon></v-btn>
								</Tooltip>
								<v-btn v-else-if="titleIconRight" small icon @click="$emit('clickTitleIconRight', $event)"><v-icon>{{titleIconRight}}</v-icon></v-btn>
							</v-col>
						</v-row>
					</template>
					<template v-else>
						<Title :centered="centeredTitle" style="width:100%;">
							{{title}}
						</Title>
					</template>
					<SubTitle :centered="centeredTitle" v-if="subTitle" style="width: 100%;">{{subTitle}}</SubTitle>
				</v-card-title>
			</template>

			<v-card-text ref="contentContainer" :class="{'mt-3': fullscreen && !noPadding, 'pa-0': noPadding}">
				<slot></slot>
			</v-card-text>
			<v-card-actions :class="{'pa-0': noPadding}">
				<slot name="buttons" v-bind="{hide}"></slot>
			</v-card-actions>
		</v-card>

	</v-dialog>
</template>

<script>
import Title    from './typography/Title.vue';
import SubTitle from './typography/SubTitle.vue';
import Tooltip  from './Tooltip.vue';

export default {
	name: 'Dialog',

	props: {
		width: {
			type:    Number,
			default: 590,
		},

		title: {
			type:    String,
			default: '',
		},

		subTitle: {
			type:    String,
			default: '',
		},

		centeredTitle: {
			type:    Boolean,
			default: false,
		},

		fullscreen: {
			type:    Boolean,
			default: false,
		},

		noPadding: {
			type:    Boolean,
			default: false,
		},

		disabled: {
			type:    Boolean,
			default: false,
		},

		titleIconRight: {
			type:    String,
			default: '',
		},

		tooltipIconRight: {
			type:    String,
			default: '',
		},

		loading: {
			type:    Boolean,
			default: false,
		},

		helpTextId: {
			type:    String,
			default: '',
		},

		helpTextIdOnHide: {
			type:    String,
			default: '',
		},
	},

	data() {
		return {
			visible: false,
		};
	},

	mounted() {
		this.$eventBus.$on('BreakpointChanged', () => {
			if(this.visible) {
				this.setDialogMargins();
			}
		});
	},

	watch: {
		visible(newValue, oldValue) {
			if(newValue === true && oldValue !== true) {
				this.$emit('show');
				this.onShow();
			} else if(newValue === false && oldValue !== false) {
				this.$emit('hide');
				this.onHide();
			}

			if(this.visible) {
				this.$nextTick(() => {
					const inputFields = this.$refs.contentContainer
						? this.$refs.contentContainer.getElementsByTagName('input')
						: [];

					for(const field of inputFields) {
						if(field.autofocus) {
							setTimeout(() => {
								field.focus();
							}, 100);
							break;
						}
					}
				});
			}
		},

		'$vuetify.application.left'() {
			this.setDialogMargins();
		},

		'$vuetify.application.right'() {
			this.setDialogMargins();
		},
	},

	methods: {
		onShow() {
			if(this.helpTextId) {
				this.$eventBus.$emit('Help:SetFromHelpTextId', {
					id:         this.helpTextId,
					title:      this.title,
					ignorePath: true,
				});
			} else {
				this.$eventBus.$emit('Help:SetFromCurrentView');
			}
		},

		onHide() {
			if(this.helpTextIdOnHide) {
				this.$eventBus.$emit('Help:SetFromHelpTextId', {
					id:         this.helpTextIdOnHide,
					ignorePath: true,
				});
			} else {
				this.$eventBus.$emit('Help:SetFromCurrentView');
			}
		},

		setDialogMargins() {
			if(!this.visible) {
				return;
			}

			try {
				if(this.fullscreen) {
					const childIndex = this.$refs.dialog.$children.findIndex((item) => item.$el.tagName === 'DIV');
					if(childIndex >= 0) {
						this.$refs.dialog.$children[childIndex].$el.children[0].style.width = `${(window.innerWidth - this.$vuetify.application.right)}px`;
					}
				} else {
					this.$refs.dialog.$children[0].$el.children[0].style['margin-left'] = `${this.$vuetify.application.left}px`;
					this.$refs.dialog.$children[0].$el.children[0].style['margin-right'] = `${this.$vuetify.application.right}px`;
				}
			} catch{}
		},

		show() {
			this.visible = true;

			this.$nextTick(() => {
				this.setDialogMargins();
			});
		},

		hide() {
			this.visible = false;
		},

		isVisible() {
			return this.visible === true;
		},
	},

	components: {
		Tooltip,
		Title,
		SubTitle,
	},
}
</script>

<style scoped>

</style>
