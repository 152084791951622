/**
 * Created by simon on 2018-11-30.
 */

import {assign, has, isPlainObject, forEach} from 'lodash';

class SessionStorage {
	constructor() {
		this.sessionStorageSupported = !!sessionStorage;

		this.sessionStorage = SessionStorage.defaults;

		if(this.sessionStorageSupported) {
			try {
				const fromLocalStorage = sessionStorage.getItem(SessionStorage.KEY);

				if(fromLocalStorage) {
					this.sessionStorage = assign(this.sessionStorage, JSON.parse(fromLocalStorage));
				}
			//eslint-disable-next-line
			} catch(error) {}
		}
	}

	static get KEY() {
		return 'DV-CRONA-LON-WEBB';
	}

	static get defaults() {
		return {
			FtgId:      '',
			SalaryYear: '',
		};
	}

	get(key, defaultValue) {
		if(!this.sessionStorageSupported) {
			return defaultValue;
		}

		return has(this.sessionStorage, key) ? this.sessionStorage[key] : defaultValue;
	}

	set(key, value) {
		if(!this.sessionStorageSupported) {
			return;
		}

		if(isPlainObject(key)) {
			forEach(key, (v, k) => this.set(k, v));
		} else {
			this.sessionStorage[key] = value;

			sessionStorage.setItem(SessionStorage.KEY, JSON.stringify(this.sessionStorage));
		}
	}

	clear() {
		forEach(this.sessionStorage, (value, key) => {
			this.set(key, null);
		});
	}
}

export default new SessionStorage();
