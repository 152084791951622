export default (value) => {
	let decimalPlaces = 2;

	if(value?.toString) {
		const stringValue = value.toString().replaceAll(',', '.').replaceAll(/\s+/g, '');
		const decSeparatorIndex = stringValue.indexOf('.') + 1;

		if(decSeparatorIndex) {
			decimalPlaces = stringValue.length - decSeparatorIndex
				? Math.min(stringValue.length - decSeparatorIndex, 4)
				: decimalPlaces;
		}
	}

	return decimalPlaces;
};
