<template>
	<v-form ref="form">
		<v-card-text>
			<template v-if="!isLoginInProgress && UsersList.length === 0">
				<BankIDMethodButtons @selected="onStartLogin" width="49%"/>
			</template>

			<template v-if="UsersList.length === 0">
				<v-row dense justify="center" v-if="isLoginInProgress">
					<v-col cols="12" class="text-center">
						<v-progress-circular v-if="!statusText" color="accent" :size="50" indeterminate class="mr-2"/>
						<p v-if="statusText">{{statusText}}</p>
					</v-col>

					<v-fade-transition hide-on-leave>
						<v-col v-if="QRCodeDataURL">
							<v-card flat height="220">
								<v-card-text class="pa-0 ma-0 text-center">
									<img v-if="QRCodeDataURL" :src="QRCodeDataURL" height="220" width="220" style="margin: 0 auto; border: 1px solid gray;"/>
								</v-card-text>
							</v-card>
						</v-col>
					</v-fade-transition>

					<v-col cols="12" v-if="Type === 1 && $vuetify.breakpoint.smAndDown && AutoStartToken && QRCodeDataURL" class="text-center">
						<v-btn depressed small color="primary" class="mr-2" @click="tryStartBankIDApp">Starta BankID på den här enheten</v-btn>
					</v-col>
				</v-row>
			</template>

			<template v-else>
				<v-list>
					<v-subheader class="subtitle-1">Välj användarkonto</v-subheader>
					<v-list-item v-for="(item, index) in UsersList" :key="index" @click="onSelectUser(item)">
						<v-list-item-content>
							<v-list-item-title>{{item.Login}}</v-list-item-title>
							<v-list-item-subtitle>{{$formatter.getRollText(item.Roll)}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</template>
		</v-card-text>
		<v-card-actions>
			<v-btn v-if="isLoginInProgress || UsersList.length > 0" style="width: 100%;" color="secondary" @click="onCancel">Avbryt</v-btn>
		</v-card-actions>
	</v-form>
</template>

<script>
import isMobile            from 'is-mobile';
import {mapActions}        from 'vuex';
import LocalStorage        from '../../lib/LocalStorage.js';
import BankIDMethodButtons from './BankIDMethodButtons.vue';

export default {
	name:       'BankID',
	components: {BankIDMethodButtons},
	data:       () => ({
		isLoginInProgress: false,
		statusText:        '',
		UsersList:         [],
		QRCodeDataURL:     '',
		Type:              null,
		AutoStartToken:    null,
	}),

	methods: {
		...mapActions(['setAuthenticated']),

		onSelectUser(user) {
			this.$tryCatch({
				task: async () => {
					const {UserAccount, HasPlus, Supplier, HasLicensOption} = await this.$http.post('/bankidauth/selectuser', {
						BankIDToken: user.BankIDToken,
						Login:       user.Login,
					});

					this.setAuthenticated({UserAccount, HasPlus, Supplier, HasLicensOption});
				},
			})
		},

		onCancel() {
			clearInterval(this.collectInterval);
			this.UsersList = [];
			this.statusText = '';
			this.isLoginInProgress = false;
			this.QRCodeDataURL = '';
			this.isCollecting = false;

			const currentOrderRef = this.currentOrderRef;

			this.currentOrderRef = null;

			if(currentOrderRef) {
				this.$tryCatch({
					task: async () => {
						await this.$http.delete(`/bankidauth/cancel/${encodeURIComponent(currentOrderRef)}`)
					},
				});
			}
		},

		onStartLogin(Type) {
			LocalStorage.set('LoginMethod', 0);

			this.$tryCatch({
				task: async () => {
					this.isLoginInProgress = true;
					this.Type = Type;

					const {OrderRef, AutoStartToken} = await this.$http.post('/bankidauth', {
						Type,
					});

					this.currentOrderRef = OrderRef;
					this.AutoStartToken = AutoStartToken;

					this.collect(OrderRef);

					if(Type === 0 || isMobile()) {
						this.tryStartBankIDApp();
					}
				},
				error: () => {
					this.isLoginInProgress = false;
				},
			});
		},

		tryStartBankIDApp() {
			const params = [
				`autostarttoken=${this.AutoStartToken}`,
				'redirect=null',
			];

			window.location = `bankid:///?${params.join('&')}`;
		},

		collect(orderRef) {
			const collect = async () => {
				if(this.isCollecting) {
					return false;
				}

				if(this.collectAttempts > 60) {
					this.onCancel();

					return;
				}

				try {
					this.isCollecting = true;

					const {
						UsersList,
						Completed,
						Cancelled,
						Message,
						QRCodeDataURL,
						PendingStart,
					} = await this.$http.get(`/bankidauth/collect/${orderRef}`);

					if(Cancelled || Completed) {
						clearInterval(this.collectInterval);
						if(Cancelled) {
							setTimeout(() => {
								this.statusText    = Message;
							}, 7000);
							setTimeout(() => {
								this.isLoginInProgress = false;
							}, 10000);
						} else {
							this.isLoginInProgress = false;
						}
					}

					if(PendingStart && QRCodeDataURL) {
						this.QRCodeDataURL = QRCodeDataURL;
						this.statusText    = 'Skanna QR-koden i BankID-appen';
					} else {
						this.QRCodeDataURL = '';
						if(!Cancelled) {
							this.statusText    = Message;
						}
					}

					if(Completed) {
						if(UsersList.length === 0) {
							this.$msgError('Det finns inga användarkonton kopplat till ditt personnummer.');
							
							return;
						}

						// if(UsersList.length === 1) {
						// 	this.onSelectUser(UsersList[0]);
						// 	return;
						// }

						this.UsersList = UsersList;
					}
				} catch {
					this.isLoginInProgress = false;
					clearInterval(this.collectInterval);
				} finally {
					this.isCollecting = false;
					this.collectAttempts = this.collectAttempts + 1;
				}
			};

			if(this.collectInterval) {
				clearInterval(this.collectInterval);
			}

			this.collectAttempts = 0;
			this.collectInterval = setInterval(collect, 1500);

			collect();
		},
	},
}
</script>

<style scoped>

</style>
