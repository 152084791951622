<template>
	<h5 :style="{color: color, 'line-height': '1.4 !important'}" :class="{
		'text-center': centered,
		'accent--text': !color,
		'display-2': large && !small,
		title: !large && !small,
		'font-weight-medium': bold,
		'subtitle-1': small,
	}" class="font-weight-light text-truncate">
		<slot></slot>
	</h5>
</template>

<script>
export default {
	name: 'SubTitle',

	props: {
		centered: {
			type:    Boolean,
			default: false,
		},

		bold: {
			type:    Boolean,
			default: false,
		},

		color: {
			type:    String,
			default: '',
		},

		large: {
			type:    Boolean,
			default: false,
		},

		small: {
			type:    Boolean,
			default: false,
		},
	},
}
</script>

<style scoped>
	h5 {
		min-height: 21px;
	}
</style>
