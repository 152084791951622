<template>
	<v-slide-x-transition hide-on-leave>
		<v-container class="fill-height" v-if="visible" fluid >
			<v-row dense align="center" justify="center">
				<v-progress-circular
								:size="size"
								color="primary"
								:class="classes"
								indeterminate
				></v-progress-circular>
			</v-row>
		</v-container>
	</v-slide-x-transition>
</template>

<script>
export default {
	name: 'Loading',

	props: {
		visible: {
			type:     Boolean,
			required: true,
		},

		centered: {
			type:    Boolean,
			default: true,
		},

		size: {
			type:    Number,
			default: 70,
		},

		classes: {
			type:    String,
			default: 'mt-5',
		},
	},
}
</script>

<style scoped>

</style>
