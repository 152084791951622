/**
 * Created by simon on 2018-11-30.
 */

import {padStart} from 'lodash';

import Validate  from '../lib/Validate.js';
import BaseModel from './BaseModel.js';

class TabellYear extends BaseModel {
	static get propertyMap() {
		return {
			TabellKod: {
				label:        'Löneartstabell',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(8),
				],
			},

			UseBeloppA01: {
				label:        'Använd A01',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA02: {
				label:        'Använd A02',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA03: {
				label:        'Använd A03',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA04: {
				label:        'Använd A04',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA05: {
				label:        'Använd A05',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA06: {
				label:        'Använd A06',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA07: {
				label:        'Använd A07',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA08: {
				label:        'Använd A08',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA09: {
				label:        'Använd A09',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA10: {
				label:        'Använd A10',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA11: {
				label:        'Använd A11',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA12: {
				label:        'Använd A12',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA13: {
				label:        'Använd A13',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA14: {
				label:        'Använd A14',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA15: {
				label:        'Använd A15',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA16: {
				label:        'Använd A16',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA17: {
				label:        'Använd A17',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA18: {
				label:        'Använd A18',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA19: {
				label:        'Använd A19',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseBeloppA20: {
				label:        'Använd A20',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},


			AvtBeloppA01: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA02: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA03: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA04: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA05: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA06: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA07: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA08: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA09: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA10: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA11: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA12: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA13: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA14: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA15: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA16: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA17: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA18: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA19: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtBeloppA20: {
				label:        'Avvika på avtal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA01: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA02: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA03: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA04: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA05: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA06: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA07: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA08: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA09: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA10: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA11: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA12: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA13: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA14: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA15: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA16: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA17: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA18: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA19: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerBeloppA20: {
				label:        'Avvika på person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BeloppA01: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA02: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA03: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA04: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA05: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA06: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA07: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA08: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA09: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA10: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA11: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA12: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA13: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA14: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA15: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA16: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA17: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA18: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA19: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BeloppA20: {
				label:        'Standardvärde',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			BelNamnA01: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA02: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA03: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA04: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA05: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA06: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA07: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA08: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA09: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA10: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA11: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA12: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA13: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA14: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA15: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA16: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA17: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA18: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA19: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			BelNamnA20: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
			},

			LonSty: {
				label:        'Löneartsstyrning',
				type:         Array,
				defaultValue: [],
				rules:        [],
			},
		};
	}

	use(count) {
		return this[`UseBeloppA${padStart(count, 2, '0')}`];
	}

	useAvt(count) {
		return this[`AvtBeloppA${padStart(count, 2, '0')}`];
	}

	usePer(count) {
		return this[`PerBeloppA${padStart(count, 2, '0')}`];
	}

	namn(count, append = '') {
		const padded = padStart(count, 2, '0');
		const name   = this[`BelNamnA${padded}`] || `A${padded}`;

		return `${append} ${name}`;
	}

	bel(count) {
		return this[`BeloppA${padStart(count, 2, '0')}`];
	}

	anyEnabled(field = '') {
		for(let i = 1; i <= 20; i++) {
			//Fn call, such as useAvt, usePer,
			if(this[`use${field}`](i)) {
				return true;
			}
		}

		return false;
	}

	getDefaultLonSty() {
		return this.LonSty.find((item) => item.IsDefault) || this.LonSty[0];
	}
}

export default TabellYear;
