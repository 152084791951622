/**
 * Created by simon on 2022-05-02.
 */

import BaseModel from './BaseModel.js';

class PayslipsSharedFilterModel extends BaseModel {
	static get propertyMap() {
		return {
			AnstNrList: {
				label:        'Anställd',
				type:         Array,
				defaultValue: () => [],
				rules:        [],
				onChange(value, model) {
					if(value === null) {
						model.AnstNr = [];
					}
				},
			},

			BrukareList: {
				label:        'Brukare',
				type:         Array,
				defaultValue: () => [],
				rules:        [],
				onChange(value, model) {
					if(value === null) {
						model.Brukare = '';
					}
				},
			},

			UseAvtal: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvtalIdList: {
				label:        'Avtal',
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			UseEgenskap: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			EgenskapIDList: {
				label:        'Egenskaper',
				type:         Array,
				defaultValue: () => ([]),
				rules:        [],
			},

			UseAvdelning: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AvdelningList: {
				label:        'Avdelning',
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			UseDigitalMailbox: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			DigitalMailboxList: {
				label:        'Anställdes val av digital brevlåda',
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			UsePerTyp: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PerTypList: {
				label:        'Personaltyp',
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			UseLoneForm: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			LoneFormList: {
				label:        'Löneform',
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			UseTotWarnings: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			TotWarnings: {
				label:        'Varningar',
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			DigitalPayslipFilter: {
				label:        'Status digital brevlåda',
				type:         String,
				defaultValue: '',
				rules:        [],
			},
		};
	}
}

export default PayslipsSharedFilterModel;
