<template>
	<v-container fluid >
		<v-row dense align="center" justify="center">
			<v-col cols="12" sm="10" md="8" lg="5">
				<v-img
								:src="$buildBrandedAssetsURL($vuetify.theme.dark ? '/logos/white.png' : '/logos/dark.png')"
								:lazy-src="$buildBrandedAssetsURL($vuetify.theme.dark ? '/logos/white.png' : '/logos/dark.png')"
								:max-height="175"
								contain
								class="mb-5 mt-5"
				/>
				<v-card outlined>
					<v-tabs v-model="tab" grow slider-color="accent" :color="$vuetify.theme.dark ? 'accent' : 'primary'">
						<template v-for="(item, index) in tabs">
							<v-tab :key="index">{{item.label}}</v-tab>
						</template>

						<template v-for="(item, index) in tabs">
							<v-tab-item :value="index">
								<v-divider></v-divider>
								<component class="pt-3" v-bind:is="item.component" v-bind="item.params"/>
							</v-tab-item>
						</template>

					</v-tabs>
				</v-card>

			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocalStorage from '../lib/LocalStorage.js';

import BankIDLogin      from './LoginForms/BankIDLogin.vue';
import UsernamePassword from './LoginForms/UsernamePassword.vue';

export default {
	name: 'Login',
	data: () => ({
		tab: parseInt(LocalStorage.get('LoginMethod'), 10) || null,
	}),

	mounted() {
		if(this.tab > this.tabs.length - 1) {
			this.tab = 0;
		}
	},

	computed: {
		tabs() {
			return [
				{label: 'BankID', component: 'BankIDLogin', params: {}},
				{label: 'Användarnamn', component: 'UsernamePassword', params: {}},
			];
		},
	},

	components: {
		UsernamePassword,
		BankIDLogin,
	},
}
</script>

<style scoped>
	::v-deep(.v-slide-group__prev, .v-slide-group__next) {
		display: none !important;
	}
</style>
