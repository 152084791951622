<template>
	<Tooltip :class="{'mr-2': useMRRight}" :text="text" v-bind="$attrs">
		<v-icon v-bind="$attrs" @click.stop.prevent="$emit('click')" :color="iconColor" :style="iconStyle">{{icon}}</v-icon>
	</Tooltip>
</template>

<script>
import Tooltip from './Tooltip.vue';

export default {
	name: 'WarningIconWithTooltip',

	props: {
		text: {
			type:     String,
			required: true,
		},

		useMRRight: {
			type:    Boolean,
			default: true,
		},

		iconStyle: {
			type:    Object,
			default: () => ({}),
		},

		icon: {
			type:    String,
			default: 'mdi-alert',
		},

		iconColor: {
			type:    String,
			default: 'error',
		},
	},

	components: {
		Tooltip,
	},
}
</script>

<style scoped>

</style>
