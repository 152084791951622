<template>
	<v-app>

		<template v-if="isRequestDemoView">
			<router-view ref="currentView"></router-view>
		</template>

		<template v-else>

			<AppNavigation v-if="Authenticated" ref="AppNavigation"/>

			<AppHelp/>

			<AppToolbar v-if="Authenticated" ref="AppToolbar"/>

			<SelectCompanyDialog persistent v-if="Authenticated && !ftgInfoIsSet" @cancel="clearAuthentication"></SelectCompanyDialog>

			<Loading v-if="!Authenticated" :visible="!Authenticated && !hasRunAuthenticationCheck"/>

			<Login v-if="!Authenticated && hasRunAuthenticationCheck"></Login>

			<v-main v-if="(Authenticated && ftgInfoIsSet && SalaryYear && !isPendingUserRights)">
				<v-fade-transition hide-on-leave>
					<keep-alive :include="keepAliveViews">
						<router-view :key="[FtgInfo.FtgKey, SalaryYear, $route.name].join('_')" ref="currentView"></router-view>
					</keep-alive>
				</v-fade-transition>
			</v-main>
		</template>

		<AppSnackbar/>
		<AppGlobalEventSourceListeners/>
		<AppNewVersionReloadPageTimer/>
		<AppConfirmDialog/>

		<LoggedOutDueToInactivityDialog ref="loggedOutDueToInactivityDialog"/>

		<Dialog ref="fileDownloadOrPrintFailedDialog" :title="fileDownloadOrPrintFailedTitle" :width="800">
			<v-container fluid>
				<v-row dense>
					<v-col cols="12">
						<v-alert border="top" colored-border type="error" elevation="2" dense class="caption">
							<div v-if="fileDownloadOrPrintErrorMessage" class="font-weight-bold">Felmeddelande</div>
							{{ fileDownloadOrPrintErrorMessage }}
						</v-alert>
					</v-col>
				</v-row>
			</v-container>

			<template v-slot:buttons="{hide}">
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="hide">Stäng</v-btn>
				<v-spacer></v-spacer>
			</template>
		</Dialog>

	</v-app>
</template>

<script>

import {mapState, mapActions}         from 'vuex';
import _                              from 'lodash';
import {PendingUserRights}            from '../shared/userRights.js';
import AppNavigation                  from './components/AppNavigation.vue';
import AppHelp                        from './components/AppHelp.vue';
import AppToolbar                     from './components/AppToolbar.vue';
import AppSnackbar                    from './components/AppSnackbar.vue';
import AppConfirmDialog               from './components/AppConfirmDialog.vue';
import AppGlobalEventSourceListeners  from './components/AppGlobalEventSourceListeners.vue';
import Loading                        from './components/Loading.vue';
import Login                          from './components/Login.vue';
import SelectCompanyDialog            from './components/SelectCompanyDialog.vue';
import AppNewVersionReloadPageTimer   from './components/AppNewVersionReloadPageTimer.vue';
import SessionStorage                 from './lib/SessionStorage.js';
import LoggedOutDueToInactivityDialog from './components/LoggedOutDueToInactivityDialog.vue';
import Dialog                         from './components/Dialog.vue';

export default {
	name:     'App',
	computed: {
		...mapState(['UserAccount', 'FtgInfo', 'Notifications', 'Authenticated', 'SalaryYear', 'SupplierInfo', 'UserRights']),
		ftgInfoIsSet() {
			return !_.isEmpty(this.FtgInfo);
		},
		isPendingUserRights() {
			return this.UserRights instanceof PendingUserRights;
		},
		isRequestDemoView() {
			return this.$route?.name === 'RequestDemoAccount';
		},
	},
	data: () => ({
		hasRunAuthenticationCheck: false,
		keepAliveViews:            [
			'DataTreeSearch',
			'Loneartsregister',
			'LoneuppdragList',
		],
		fileDownloadOrPrintFailedTitle:  '',
		fileDownloadOrPrintErrorMessage: '',
	}),

	created() {
		this.checkAuthentication();
		this.registerDarkModePreferenceHandler();

		this.$eventBus.$on('fileDownloadOrPrintFailed', this.showFileDownloadOrPrintFailedDialog.bind(this));

		document.title = this.SupplierInfo.WebPageTitle;
	},

	watch: {
		'$vuetify.breakpoint': {
			deep: true,
			handler() {
				this.$eventBus.$emit('BreakpointChanged');
			},
		},

		'$route.name'() {
			if(this.keepAliveViews.includes(this.$route.name)) {
				this.$nextTick(() => {
					if(this.$refs.currentView) {
						this.$refs.currentView.$el.style.removeProperty('display');
						if(this.$refs.currentView.onKeepaliveRefresh) {
							this.$refs.currentView.onKeepaliveRefresh();
						}
					}
				});
			}
		},
	},

	methods: {
		...mapActions(['setAuthenticated', 'setSalaryYear', 'confirmDialog']),

		showFileDownloadOrPrintFailedDialog({FormId, Message, DVCode, URLPath}) {
			if(URLPath.toUpperCase().startsWith('/PRINT')) {
				this.fileDownloadOrPrintFailedTitle = 'Utskrift misslyckades';
			} else {
				this.fileDownloadOrPrintFailedTitle = 'Filnedladdning misslyckades';
			}

			this.fileDownloadOrPrintErrorMessage = Message;
			this.$refs.fileDownloadOrPrintFailedDialog.show();
		},

		showInactivityDialog() {
			this.$refs.loggedOutDueToInactivityDialog.show();
		},

		registerDarkModePreferenceHandler() {
			if(!window.matchMedia) {
				return;
			}

			window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
				this.$vuetify.theme.dark = !!event.matches;
			});
		},

		clearAuthentication() {
			this.$tryCatch({
				task: async () => {
					SessionStorage.clear();
					await this.$http.get('/me/clearSession');

					this.$router.go();
				},
			});
		},

		checkHasUnsavedChanges(callback) {
			const {currentView} = this.$refs;

			const childHasUnsavedChanges = (child) => {
				if(child && child.hasUnsavedChanges && child.hasUnsavedChanges()) {
					return true;
				}

				const {$children = []} = child;

				for(const subChild of $children) {
					if(childHasUnsavedChanges(subChild)) {
						return true;
					}
				}

				return false;
			};

			const anyChildWithUnsavedChanges = () => {
				if(!currentView) {
					return false;
				}

				return childHasUnsavedChanges(currentView);
			};

			if(anyChildWithUnsavedChanges()) {
				const saveFn = currentView.onSave;
				const altConfirmSettings = saveFn
					? {
						showAltConfirm:      true,
						altConfirmText:      'Spara och lämna',
						altConfirmColor:     'primary',
						autoFocusAltConfirm: true,
						onAltConfirm:        () => {
							try {
								const res = saveFn();

								if(res && res.then) {
									res.then(() => {
										callback(true);
									});
								}
							} catch {}
						},
					}
					: null;


				return this.confirmDialog({
					title:            'Osparade ändringar',
					text:             `Du har osparade ändringar i vyn. Är du säker på att du vill lämna?`,
					cancelText:       'Lämna',
					confirmText:      'Stanna kvar',
					confirmColor:     'primary',
					autoFocusConfirm: !altConfirmSettings,
					onCancel:         () => callback(true),
					onConfirm:        () => {
						if('function' === typeof currentView.onUnsavedChangesPromptOptedToStayInView) {
							currentView.onUnsavedChangesPromptOptedToStayInView();
						}

						return callback(false)
					},
					...altConfirmSettings,
				});
			}

			return callback(true);
		},

		async checkAuthentication() {
			try {
				const {UserAccount, FtgInfo, SalaryYears, SessionSalaryYear, HasPlus, Supplier, HasLicensOption, AutoLogoutDueToInactivity} = await this.$http.get('/me', {
					headers: {
						'dv-selected-company': SessionStorage.get('FtgId', ''),
						'dv-selected-year':    SessionStorage.get('SalaryYear', ''),
					},
				});

				this.hasRunAuthenticationCheck = true;

				if(AutoLogoutDueToInactivity) {
					this.showInactivityDialog();
					
					return;
				}

				this.setAuthenticated({UserAccount, FtgInfo, SalaryYears, SessionSalaryYear, HasPlus, Supplier, HasLicensOption});

				if(SessionSalaryYear) {
					this.setSalaryYear({Year: SessionSalaryYear});
				}
			} catch {
				this.hasRunAuthenticationCheck = true;
			}
		},
	},

	components: {
		Dialog,
		LoggedOutDueToInactivityDialog,
		AppNewVersionReloadPageTimer,
		AppNavigation,
		AppToolbar,
		AppSnackbar,
		AppConfirmDialog,
		AppGlobalEventSourceListeners,
		SelectCompanyDialog,
		Loading,
		Login,
		AppHelp,
	},
}
</script>

<style scoped>
	.v-toolbar__content {
		border: 50px solid #000;
		display: none !important;
		padding: 0 !important;
		padding-right: 24px;
	}

	/*Gäller valideringsmeddelanden. Problem med att visa ÅÄÖ annars, lägger till +0.2 från default (1->1.2)*/
	::v-deep(.v-messages__message) {
		line-height: 1.2;
	}

	/*Samma sak här gällande ÅÄÖ, lägger till +1 px från default (20->21)*/
	::v-deep(.v-text-field input) {
		line-height: 21px;
	}

	/*Samma sak här, för <v-autocomplete>*/
	::v-deep(.v-select-list .v-list-item .v-list-item__title) {
		line-height: 1.4;
	}

	::v-deep(.v-list-item__title) {
		line-height: 1.4 !important;
	}

	::v-deep(.cursor-pointer) {
		cursor: pointer;
	}

	::v-deep(.align-end) {
		-webkit-box-align: end !important;
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}

	::v-deep(.inherit-background) {
		background: inherit;
	}

	::v-deep(.text-right) {
		text-align: right !important;
	}

	/*Makes dense selectlists not always have bold font*/
	::v-deep(.v-select-list .v-list--dense .v-list-item .v-list-item__title) {
		font-weight: normal !important;
	}

	::v-deep(.v-select-list .v-list--dense .v-list-item .v-list-item__subtitle) {
		font-weight: normal !important;
	}

	/*Makes autocomplete search matches bold instead of gray background*/
	::v-deep(.v-list-item__mask) {
		font-weight: bold;
		background: none !important;
		color: inherit !important;
	}

	/* Makes checkboses in dense v-autocompletes (multiple) not so damn large */
	::v-deep(.v-list--dense > .v-list-item > .v-list-item__action) {
		margin-top: 0;
		margin-bottom: 0;
	}

	::v-deep(.v-input .v-label) {
		line-height: 23px;
	}

	::v-deep(.theme--light .v-data-table thead th) {
		border-bottom: 1px solid #dcdcdc;
		background-color: #f1f1f1;
	}

	::v-deep(.theme--dark .v-data-table thead th) {
		border-bottom: 1px solid #dcdcdc;
		background-color: #212121;
	}

	/* Sets min-height for checkboxes. May not work everywhere, so may need to delete. This is to match other types of inputfields for vertical alignment. */
	::v-deep(.v-input--checkbox:not(.table-checkbox) > .v-input__control) {
		min-height: 32px;
	}


	/* This is to handle Chrome Webkit autofill backgrounds, they look hideous otherwise..*/
	::v-deep(label.theme--dark + input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active) {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: #fff !important; /* inherit only works in Safari */
		-webkit-text-size-adjust: inherit !important;
	}

	::v-deep(label.theme--light + input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active) {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: #000 !important; /* inherit only works in Safari */
		-webkit-text-size-adjust: inherit !important;
	}

	/* When dark theme, then make keychain icon white */
	::v-deep(label.theme--dark + input::-webkit-credentials-auto-fill-button){
		background-color: #fff !important;
	}

	/* Hide credentials-auto-fill-button in password inputs, only visible on other inputs */
	::v-deep(input[type=password]::-webkit-credentials-auto-fill-button) {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
	}

	::v-deep(.summary-row) {
		background-color: #f9f9f9;
	}

	::v-deep(.theme--dark .summary-row) {
		background-color: #303030 !important;
	}

	::v-deep(.v-list-item--active.primary--text) {
		color: #FF9800 !important;
	}
</style>
