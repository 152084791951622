/**
 * Created by simon on 2018-11-16.
 */

export default {
	'badge':        'Badge',
	'dataIterator': {
		'itemsPerPageText': 'Rader per sida:',
		'itemsPerPageAll':  'Alla',
		'pageText':         '{0}-{1} av {2}',
		'noResultsText':    'Inga resultat hittades',
		'nextPage':         'Nästa sida',
		'prevPage':         'Föregående sida',
		'loadingText':      'Laddar...',
	},
	'dataTable': {
		'itemsPerPageText': 'Rader per sida:',
		'ariaLabel':        {
			'sortDescending':     ': Sorterat fallandes.',
			'sortAscending':      ': Sorterat stigandes.',
			'sortNone':           ': Ej sorterat.',
			'activateAscending':  ': Aktivera för att sortera stigande.',
			'activateDescending': ': Aktivera för att sortera fallande.',
		},
	},
	'noDataText': 'Inget resultat hittades',
	'datePicker': {
		'itemsSelected':      '{0} markerade',
		'nextMonthAriaLabel': 'Nästa månad',
		'nextYearAriaLabel':  'Nästa år',
		'prevMonthAriaLabel': 'Föregående månad',
		'prevYearAriaLabel':  'Föregående år',
	},
	'dataFooter': {
		'itemsPerPageText': 'Rader per sida:',
		'itemsPerPageAll':  'Alla',
		'nextPage':         'Nästa sida',
		'prevPage':         'Föregående sida',
		'firstPage':        'Första sidan',
		'lastPage':         'Sista sidan',
		'pageText':         '{0}-{1} av {2}',
	},
	'calendar': {
		'moreEvents': '{0} till',
	},
	'carousel': {
		'prev': 'Föregående',
		'next': 'Nästa',
	},
	'fileInput': {
		'counter':     '{0} filer',
		'counterSize': '{0} filer ({1} totalt)',
	},
	'input': {
		'appendAction':  '{0} åtgärd',
		'clear':         'Rensa {0}',
		'prependAction': '{0} förberedd åtgärd',
	},
	'loading': 'Laddar...',
}
