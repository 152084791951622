/**
 * Created by simon on 2018-12-12.
 */


import _           from 'lodash';
import moment      from 'moment';
import AutoNumeric from 'autonumeric';
import store       from '../store/index.js';

import {
	PerTyp,
	LoneForm,
	KlassTyp,
	SkatteTyp,
	UtbetPer,
	PrelSkattTyp,
	UtbetTyp,
	AmfTyp,
	Roll,
	SemUttagTyp,
	AnstForm,
	SjukFranvaroTyp,
	KostnSlag,
	ForaReportType,
	ForaAvailableParameters
} from './SelectOptions.js';
import getAutoDecimaplaces from './helpers/getAutoDecimaplaces.js';

const autoNumericOptions = {
	digitGroupSeparator:           ' ',
	decimalCharacter:              ',',
	decimalCharacterAlternative:   '.',
	decimalPlaces:                 2,
	negativePositiveSignPlacement: 'l',
};

const BrukareNamnTypTextMap = {
	BRUKARE: {
		Brukare:             'Brukare',
		Brukaren:            'Brukaren',
		Brukarens:           'Brukarens',
		BrukarePlural:       'Brukare',
		Brukarnummer:        'Brukarnummer',
		Brukarnumret:        'Brukarnumret',
		Brukarlista:         'Brukarlista',
		BrukareAbbreviation: 'Bru',
		Brukarregistret:     'Brukarregistret',
		Brukarkontot:        'Brukarkontot',
	},
	ARBETSLEDARE: {
		Brukare:             'Arbetsledare',
		Brukaren:            'Arbetsledaren',
		Brukarens:           'Arbetsledarens',
		BrukarePlural:       'Arbetsledare',
		Brukarnummer:        'Arbetsledarnummer',
		Brukarnumret:        'Arbetsledarnumret',
		Brukarlista:         'Arbetsledarlista',
		BrukareAbbreviation: 'Arb',
		Brukarregistret:     'Arbetsledarregistret',
		Brukarkontot:        'Arbetsledarkontot',
	},
	KUND: {
		Brukare:             'Kund',
		Brukaren:            'Kunden',
		Brukarens:           'Kundens',
		BrukarePlural:       'Kunder',
		Brukarnummer:        'Kundnummer',
		Brukarnumret:        'Kundnumret',
		Brukarlista:         'Kundlista',
		BrukareAbbreviation: 'Kund',
		Brukarregistret:     'Kundregistret',
		Brukarkontot:        'Kundkontot',
	},
};

class Formatter {
	static escape(value) {
		return _.escape(value);
	}

	static decimal(value, options = {}) {
		if('string' === typeof value) {
			const isNegative = value[0] === '-';

			value = value.replaceAll(',', '.').replaceAll(' ', '').replace(/[^\d.]+/, '');
			if(!value.match(/\d/)) {
				value = 0;
			} else if(isNegative) {
				value = -value;
			}
		}

		if(options?.decimalPlaces === 'auto') {
			options.decimalPlaces = getAutoDecimaplaces(value);
		}

		const formatterOptions = {...autoNumericOptions, ...options};
		let formattedValue = AutoNumeric.format(0, formatterOptions);

		try {
			const autoNumericFormat = AutoNumeric.format(value, formatterOptions);
			formattedValue = _.isNil(autoNumericFormat) ? formattedValue : autoNumericFormat;
		} catch{}

		if(options.allowZero !== true && (formattedValue.replaceAll(/,|0/g, '') === '' || formattedValue === '0')) {
			return '';
		}

		return options.unit ? [formattedValue, options.unit].join(' ') : formattedValue;
	}

	static unformatDecimal(value, options = {}) {
		return AutoNumeric.unformat(value, {...autoNumericOptions, ...options});
	}

	static hourDiff(startTime, endTime, startDate, endDate) {
		if(!startTime || !endTime) {
			return 0;
		}

		startTime = startTime.replace(/\D+/g, '');
		endTime   = endTime.replace(/\D+/g, '');

		const sHH = startTime.slice(0, 2);
		const sMM = startTime.slice(2);
		const eHH = endTime.slice(0, 2);
		const eMM = endTime.slice(2);

		const start = moment(startDate || new Date())
			.hour(parseFloat(sHH))
			.minute(parseFloat(sMM));
		const end = moment(endDate || new Date())
			.hour(parseFloat(eHH))
			.minute(parseFloat(eMM));

		return moment.duration(end.diff(start)).asHours();
	}

	static time(value = '') {
		const val              = [...value.replaceAll(':|,|.', '')];
		const length           = val.length;
		const [H1, H2, T1, T2] = val;

		switch (length) {
			case 1: {
				value = `0${H1}:00`;
		
				break;
			}
			case 2: {
				value = `${H1 + H2}:00`;
		
				break;
			}
			case 3: {
				value = `0${H1}:${H2}${T1}`;
		
				break;
			}
			case 4: {
				value = `${H1 + H2}:${T1}${T2}`;
		
				break;
			}
			default: if(length === 5 && !_.includes(value, ':')) {
				value = Formatter.time(value.slice(0, 4));
			} else {
				return value;
			}
		}

		return value;
	}

	static date(value) {
		const currentDate        = new Date();
		const currentYear        = currentDate.getFullYear();
		const currentMonthPadded = _.padStart(currentDate.getMonth() + 1, 2, '0');

		let val      = _.split(_.replace(_.replace(value, /[^\d-]/g, ''), /-|\s/g, ''), '');
		const length = val.length;

		let YY;
		let MM;
		let DD;

		switch (length) {
			case 6: {
				YY = `20${val[0]}${val[1]}`;
				MM = `${val[2]}${val[3]}`;
				DD = `${val[4]}${val[5]}`;
		
				break;
			}
			case 9: {
				YY = `${val[0]}${val[1]}${val[2]}${val[3]}`;
				MM = `${val[5]}${val[6]}`;
				DD = `${val[7]}${val[8]}`;
		
				break;
			}
			case 5: {
				YY = `20${val[0]}${val[1]}`;
				if(parseInt(val[2] + val[3]) > 12) {
					YY = `200${val[0]}`;
					MM = `${val[1]}${val[2]}`;
					DD = val[3] + val[4];
				} else {
					YY = `20${val[0]}${val[1]}`;
					MM = `${val[2]}${val[3]}`;
					DD = `0${val[4]}`;
				}
		
				break;
			}
			case 1: {
				return `${currentYear}-${currentMonthPadded}-0${val[0]}`
			}
			case 2: {
				return `${currentYear}-${currentMonthPadded}-${val[0]}${val[1]}`
			}
			case 3: {
				return `${currentYear}-0${val[0]}-${val[1]}${val[2]}`;
			}
			case 4: {
				return `${currentYear}-${val[0]}${val[1]}-${val[2]}${val[3]}`;
			}
			case 7: {
				return [
					val[0], val[1], val[2], val[3],
					val[4], val[5],
					val[6], val[7],
				].join('-');
			}
			default: {
				YY = `${val[0]}${val[1]}${val[2]}${val[3]}`;
				MM = `${val[4]}${val[5]}`;
				DD = `${val[6]}${val[7]}`;
			}
		}
		if(length === 6 || length === 5 || length === 8) {
			const currentYear = moment().year();

			if(parseInt(YY, 10) > currentYear + 10) {
				YY = (parseInt(YY, 10) - 100).toString();
			}

			val = `${YY}-${MM}-${DD}`;
		} else {
			return value;
		}

		return val;
	}

	static brukareText(item) {
		return `${item.Brukare} - ${[item.FoNamn, item.EfNamn].join(' ')}`;
		// return [item.FoNamn, item.EfNamn].join(' ');
	}

	static brukareTextFromId(Brukare) {
		if(Brukare === '') {
			return '';
		}

		const brukare = _.find(store.state.BrukarLista, {Brukare});

		return brukare ? Formatter.brukareText(brukare) : '<Saknas>';
	}

	static brukarAnsvText(item) {
		return `${item.Sign} - ${item.Namn}`;
	}

	static brukarAnsvTextFromId(Sign) {
		if(Sign === '') {
			return '';
		}

		const brukarAnsv = _.find(store.state.BrukarAnsvLista, {Sign});

		return brukarAnsv ? Formatter.brukarAnsvText(brukarAnsv) : '<Saknas>';
	}

	static avdelningText(item) {
		return item.Namn;
	}

	static avdelningTextFromId(Avdelning) {
		if(Avdelning === '') {
			return '';
		}

		const avdelning = _.find(store.state.AvdelningLista, {Avdelning});

		return avdelning ? Formatter.avdelningText(avdelning) : '<Saknas>';
	}

	static employeeText(item) {
		return `${item.AnstNr} - ${[item.FoNamn, item.EfNamn].join(' ')}`;
		// return [item.FoNamn, item.EfNamn].join(' ');
	}

	static employeeTextFromId(AnstNr) {
		const employee = _.find(store.state.PerLista, {AnstNr});

		return employee ? Formatter.employeeText(employee) : '<Saknas>';
	}

	static stdperText(item) {
		//debugger;
		return `${item.Id} - ${item.Namn}`;
	}

	static kontoNrText(item) {
		return [item.KontoNr, item.Namn].join(' - ');
	}

	static kontoNrTextFromId(KontoNr) {
		if(KontoNr === '') {
			return '';
		}

		const konto = _.find(store.state.KontoReg, {KontoNr});

		return konto ? [konto.KontoNr, konto.Namn].join(' - ') : '<Saknas>';
	}

	static tidKodText(TidKod) {
		const tidkod = _.find(store.state.TidKoder, {TidKod});

		return tidkod ? tidkod.BenText : '<Saknas>';
	}

	static kostnTypTextFromId(KostnTyp) {
		if(KostnTyp === '') {
			return '';
		}

		const kostnTyp = _.find(store.state.LSSKostnTypLista, {KostnTyp});

		return kostnTyp ? kostnTyp.Benamning : '<Saknas>';
	}

	static resEnhetNamn(number) {
		const ftgInfo = store.state.FtgInfo;

		return ftgInfo[`ResEnhet${number}Namn`] || `Resultatenhet ${number}`;
	}

	static resEnhetTextFromValue(id, value) {
		if(value === '') {
			return '';
		}

		const ftgInfo         = store.state.FtgInfo;
		const ResultatEnheter = store.state.ResultatEnheter || {};

		if(ftgInfo[`RegisterResEnh${id}`]) {
			const item = _.find(ResultatEnheter[id], {ResEnh: value});

			if(item && item.Namn) {
				return item.Namn;
			}
		}

		return value;
	}

	static arbSchemaText(SchemaID) {
		if(SchemaID === '') {
			return '';
		}

		const arbSchema = _.find(store.state.ArbSchemaList, {SchemaID});

		return arbSchema ? arbSchema.BenText : '<Saknas>';
	}

	static translateKostnSlag(kostnSlag) {
		return _.get(_.find(KostnSlag, {value: kostnSlag}), 'text', kostnSlag);
	}

	static translateForaReportType(foraReportType) {
		return _.get(_.find(ForaReportType, {value: foraReportType}), 'text', foraReportType);
	}

	static translateKlassTyp(klassTyp) {
		return _.get(_.find(KlassTyp, {value: klassTyp}), 'text', klassTyp);
	}

	static translateSkatteTyp(skatteTyp) {
		return _.get(_.find(SkatteTyp, {value: skatteTyp}), 'text', skatteTyp);
	}

	static translatePrelSkattTyp(prelSkattTyp) {
		return _.get(_.find(PrelSkattTyp, {value: prelSkattTyp}), 'text', prelSkattTyp);
	}

	static translateArbAvgTyp(ArbAvgTyp) {
		const map = {
			'99': 'Svenska arbetsgivaravgifter',
			'69': 'Utsänd personal',
			'65': 'Ej fast driftställe i Sverige',
			'00': 'Fri från avgift',
		};

		//Tex 55, 56, 57, 58, 59
		if(!_.has(map, ArbAvgTyp)) {
			ArbAvgTyp = '99';
		}

		return map[_.toString(ArbAvgTyp)] || '';
	}

	static perTypToText(perTyp) {
		return _.get(_.find(PerTyp, {value: perTyp}), 'text', perTyp);
	}

	static loneFormToText(loneForm) {
		return _.get(_.find(LoneForm, {value: loneForm}), 'text', loneForm);
	}

	static anstFormToText(anstForm) {
		return _.get(_.find(AnstForm, {value: anstForm}), 'text', anstForm);
	}

	static translateSjukfranvaroTyp(sjukFranvaroTyp) {
		if(sjukFranvaroTyp === '0') {
			return '';
		}

		if(sjukFranvaroTyp === 'A') {
			return 'Karensavdrag';
		}

		return _.get(_.find(SjukFranvaroTyp, {value: sjukFranvaroTyp}), 'text', sjukFranvaroTyp);
	}

	static agreementToText(AvtalId) {
		const agreement = _.find(store.state.AgreementsList, {AvtalId});

		return agreement ? agreement.BenText : '';
	}

	static vacationAgreementToText(SemAvtalKod) {
		const vacationAgreement = _.find(store.state.VacationAgreementsList, {AvtalKod: SemAvtalKod});

		return vacationAgreement ? vacationAgreement.BenText : '';
	}

	static lonArtText(item) {
		return [item.LonArtNr, item.BenText].join(' - ');
	}

	static utbetPerToText(utbetPer) {
		return _.get(_.find(UtbetPer, {value: utbetPer}), 'text', utbetPer);
	}

	static utbetTypToText(utbetTyp) {
		return _.get(_.find(UtbetTyp, {value: utbetTyp}), 'text', utbetTyp);
	}

	static amfTypTextFromId(amfTyp) {
		return _.get(_.find(AmfTyp, {value: amfTyp}), 'text', amfTyp);
	}

	static persNr(value = '') {
		value = value.replace(/\D/g, '');

		if(value.length >= 10) {
			if(value.length === 10) {
				const currentYear = (new Date()).getFullYear();
				const inputYear = parseInt(value.slice(0, 2), 10);

				value = inputYear <= parseInt(currentYear.toString().slice(2), 10) ? `${parseInt(currentYear.toString().slice(0, 2))}${value}` : `${parseInt((currentYear - 100).toString().slice(0, 2))}${value}`;
			}

			return [value.slice(0, 8), value.slice(8)].join('-');
		}

		return value;
	}

	static getRollText(roll) {
		if(roll === 'LIC') {
			return 'Licensanvändare';
		}

		if(roll === 'BRU') {
			return Formatter.brukareNamnTypText('Brukare');
		}

		const item = Roll.find((item) => item.value === roll);

		return item ? item.text : roll;
	}

	static getSemUttagTypText(semUttagTyp) {
		const item = SemUttagTyp.find((item) => item.value === semUttagTyp);

		return item ? item.text : semUttagTyp;
	}

	static payslipStatusAsText(payslip) {
		const {Klarmarkerad, Attesterad, Backad, Utskriven, Utbetald} = payslip;
		const {UseAttestWebLonHuv} = store.state.FtgInfo;

		if(Backad) {
			return 'Återförd';
		} else if(Utbetald) {
			return 'Utbetald';
		} else if(Utskriven) {
			return 'Utskriven';
		} else if(UseAttestWebLonHuv && Attesterad) {
			return 'Attesterad';
		} else if(Klarmarkerad) {
			return 'Klarmarkerad'
		}

		return 'Skapad';
	}

	static momentFormat(value, format = 'YYYY-MM-DD') {
		return moment(value).format(format);
	}

	static momentFromNow(value, withoutSuffix = false) {
		return moment(value).fromNow(withoutSuffix);
	}

	static lssPeriod(value = '') {
		return `${value.slice(0, 4)} ${value.slice(4)}`
	}

	static formatPersNrOrgNrTo12(value, {dash = false, serialNr = false} = {}) {
		value = _.replace(value, /\D+/, '');

		if(value.length === 10) {
			if(value.slice(2, 3) < '2') {
				//Skapa sekelsiffra med utångspunkt att ingen är >= 100 år
				const century = Math.trunc(((new Date()).getFullYear() - parseInt(value.slice(0, 2))) / 100);

				value = `${century}${value}`;
			} else {
				value = `16${value}`
			}
		}

		if(dash) {
			value = value.length === 11 && serialNr ? `${value.slice(0, Math.max(0, value.length - 3))}-${value.slice(- 3)}` : `${value.slice(0, Math.max(0, value.length - 4))}-${value.slice(- 4)}`;
		}

		return value;
	}

	static foraParameterToText(parameter) {
		const item = ForaAvailableParameters.find((item) => item.value === parameter);

		return item ? item.text : parameter;
	}

	static brukareNamnTypText(fieldName) {
		const textMap = BrukareNamnTypTextMap[store.state.FtgInfo.BrukareNamnTyp] || BrukareNamnTypTextMap.BRUKARE;

		return textMap[fieldName];
	}
}

export default Formatter;
