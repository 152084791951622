<template>
	<div></div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
	name: 'AppGlobalEventSourceListeners',

	mounted() {
		this.$registerEventSourceHandler('USER_NOTIFICATION_CREATED', this.onHandleNewUserNotification);
		this.$registerEventSourceHandler('NEW_UNREAD_MESSAGE_RECEIVED', this.onNewMessageReceived);
		this.$registerEventSourceHandler('USERRIGHTSGROUPS_USER_SETTINGS_UPDATED', this.onUserRightsGroupUserSettingsUpdated);
	},

	methods: {
		...mapActions(['addNotification', 'addUnreadMessagesCount', 'loadUserRightsGroup']),

		onHandleNewUserNotification(payload) {
			this.addNotification(payload);
		},

		onNewMessageReceived() {
			this.addUnreadMessagesCount(1);
		},

		onUserRightsGroupUserSettingsUpdated() {
			this.loadUserRightsGroup(({isSTD}) => {
				if(!isSTD) {
					this.$msgInfo('Dina programbehörigheter har uppdaterats av en administratör.')
				}
			});
		},
	},
}
</script>

<style scoped>

</style>
