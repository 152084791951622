<template>
	<!--div Used for padding content top-->
	<div style="width: 100%; height: 70px;">
		<v-app-bar fixed dense :style="computedStyle" flat>
			<v-row dense style="margin-top: -3px;">
				<v-col cols="12" class="pa-0 ma-0 mb-1">
					<v-divider class="pa-0 ma-0"/>
				</v-col>
				<v-col class="pl-2 bottom-toolbar-slot" v-if="hasSlot('left')" :cols="hasSlot('right') ? colsLeft : ''">
					<slot name="left"></slot>
				</v-col>

				<v-col class="bottom-toolbar-slot text-center" v-if="hasSlot('center')">
					<slot name="center"></slot>
				</v-col>

				<v-col class="text-right bottom-toolbar-slot pr-2" :cols="hasSlot('left') ? colsRight : ''">
					<slot name="right"></slot>
				</v-col>
			</v-row>
		</v-app-bar>
	</div>
</template>

<script>
export default {
	name: 'BottomToolbar',

	props: {
		fullWidth: {
			type:    Boolean,
			default: false,
		},

		colsLeft: {
			type:         String,
			defaultValue: '',
		},

		colsRight: {
			type:         String,
			defaultValue: '',
		},
	},

	methods: {
		hasSlot(name) {
			return this.$slots[name] || this.$scopedSlots[name];
		},
	},

	computed: {
		computedStyle() {
			return {
				'top':          'auto',
				'bottom':       0,
				'margin-left':  this.fullWidth ? undefined : `${this.$vuetify.application.left}px`,
				'margin-right': `${this.$vuetify.application.right}px`,
			};
		},
	},
}
</script>

<style scoped>
	::v-deep(.bottom-toolbar-slot > button) {
		margin-left: 5px;
	}

	::v-deep(.v-toolbar__content) {
		padding: 0;
		padding-left: 3px;
	}
</style>
