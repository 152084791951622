<template>
	<v-card-text class="pa-0">
		<v-list dense>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title>Nybilpris</v-list-item-title>
					<v-list-item-subtitle>{{selectedVehicle.Text}} ({{selectedVehicle.Code}})</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>{{$formatter.decimal(calcResult.BilNypris, {unit: 'SEK', allowZero: true,  decimalPlaces: 0})}}</v-list-item-action>
			</v-list-item>

			<v-list-item v-if="calcResult.ComparisonVehicleText">
				<v-list-item-content>
					<v-list-item-title>Nybilspris för jämförbar bil</v-list-item-title>
					<v-list-item-subtitle>{{calcResult.ComparisonVehicleText}} ({{calcResult.ComparisonVehicleCode}})</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>{{$formatter.decimal(calcResult.ComparisonVehiclePrice, {unit: 'SEK', allowZero: true,  decimalPlaces: 0})}}</v-list-item-action>
			</v-list-item>

			<v-list-item v-if="calcResult.BilExtra">
				<v-list-item-content>
					<v-list-item-title>Extrautrustning</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action>{{$formatter.decimal(calcResult.BilExtra, {unit: 'SEK', allowZero: true,  decimalPlaces: 0})}}</v-list-item-action>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title>Fordonsskatt</v-list-item-title>
					<v-list-item-subtitle>Bilens fordonsskatt enligt vägtrafikskattelagen</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>{{$formatter.decimal(calcResult.BerFordonsskatt, {unit: 'SEK', allowZero: true,  decimalPlaces: 0})}}</v-list-item-action>
			</v-list-item>

			<v-list-item v-if="SalaryYear < 2021">
				<v-list-item-content>
					<v-list-item-title>Reducering elbilar, laddhybridbilar, gas (ej gasol)</v-list-item-title>
					<v-list-item-subtitle>{{$formatter.decimal(calcResult.BerMiljoRabattPrc, {unit: '%', allowZero: true,  decimalPlaces: 0})}} nedsättning av förmånsvärdet. Högst {{$formatter.decimal(10000, {unit: 'SEK'})}} för helt år</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>{{$formatter.decimal(-calcResult.BerMiljoRabatt, {unit: 'SEK', allowZero: true,  decimalPlaces: 0})}}</v-list-item-action>
			</v-list-item>

			<v-divider></v-divider>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="font-weight-bold">{{calcResult.BerFormanTotLabel}}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action class="accent--text">{{$formatter.decimal(calcResult.BerFormanTot, {unit: 'SEK',  decimalPlaces: 0})}}</v-list-item-action>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="font-weight-bold">{{calcResult.BerFormanLonLabel}}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action class="accent--text">{{$formatter.decimal(calcResult.BerFormanLon, {unit: 'SEK',  decimalPlaces: 0})}}</v-list-item-action>
			</v-list-item>
		</v-list>
	</v-card-text>

</template>

<script>
import {mapState} from 'vuex';

export default {
	name: 'BilformanCalcResultCardText',

	props: {
		calcResult: {
			type:     Object,
			required: true,
		},

		selectedVehicle: {
			type:     Object,
			required: true,
		},
	},

	computed: {
		...mapState(['SalaryYear']),
	},
}
</script>

<style scoped>

</style>
