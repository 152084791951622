/**
 * Created by simon on 2020-11-02.
 */

const ICONS = {
	ERROR:   {Type: 'mdi-alert-octagon-outline', Color: 'error'},
	SUCCESS: {Type: 'mdi-check-outline', Color: 'success'},
	INFO:    {Type: 'mdi-alert-circle-outline', Color: 'info'},
};

export default function({
	Event,
	Type,
	Id,
	Title,
	Message,
	Context = {},
	TimeStamp,
	HasRead = false,
}) {
	let routeConfig = null;
	const {
		FtgId,
		FtgNamn,
		Period,
		ExternalURL,
	} = Context;

	switch(Event.split('_')[0].toUpperCase()) {
		case 'AGI':
		case 'AGIAPI':
			if(Period) {
				routeConfig = {
					name:   'EmployerDeclaration',
					params: {
						Period,
					},
				};
			}
			break;
	}

	if(ExternalURL) {
		routeConfig = {
			isExternal: true,
			url:        ExternalURL,
		};
	}

	return {
		Id,
		Title,
		Message,
		Icon:        ICONS[Type],
		TimeStamp,
		FtgId,
		FtgNamn,
		RouteConfig: routeConfig,
		HasRead,
	};
}
