<template>
	<v-row dense justify="end">
		<v-col cols="12" class="text-right">
			<v-btn :width="width" :disabled="disabled" style="display: inline-block;" color="primary" @click="$emit('selected', 1)"><v-icon left>mdi-cellphone</v-icon> Mobilt BankID</v-btn>
			<v-btn :width="width" :disabled="disabled" style="display: inline-block;" class="ml-2" color="secondary" @click="$emit('selected', 0)"><v-icon left>mdi-laptop</v-icon> BankID på fil</v-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'BankIDMethodButtons',

	props: {
		width: {
			type:    String,
			default: 'auto',
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
	},
}
</script>

<style scoped>

</style>
