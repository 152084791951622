/**
 * Created by simon on 2023-03-02.
 */

const galenicaFtgKeyList = new Set([
	'5c9498b4-f882-43e7-b7da-23589642878d',
	'e5bd77d6-f87f-412b-8460-a64510125be4',
]);

const sjomarkenFtgKeyList = new Set([
	'9a878704-db39-4562-9cd5-f43b855d06c3',
]);

const warmfiberFtgKeyList = new Set([
	'f8d77547-a0a2-44b2-95d9-8a7ab023646f',
]);

const cactusRailFtgKeyList = new Set([
	'c10340ca-3461-45af-acbf-512d6612aecf',
	'3ccaae14-c526-4382-b6ed-d5d144d4e194',
]);

const sverekFtgKeyList = new Set([
	'1a6bfa9a-495f-4f4e-95a5-d06e960e6d9d',
	'cb7c6598-9422-4e46-bd95-0a3eceea3ab4',
]);

export function validateGalenicaFeaturesEnabled(LicNr, FtgKey) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return (LicNr === 'WEB999400' && galenicaFtgKeyList.has(FtgKey))
}

export function validateMazarsReportsEnabled(LicNr) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return LicNr === 'WEB065622';
}

export function validateSjomarkenFeaturesEnabled(LicNr, FtgKey) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return (LicNr === 'WEB999400' && sjomarkenFtgKeyList.has(FtgKey))
}

export function validateWarmfiberFeaturesEnabled(LicNr, FtgKey) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return (LicNr === 'WEB999400' && warmfiberFtgKeyList.has(FtgKey))
}

export function validateCactusRailFeaturesEnabled(LicNr, FtgKey) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return (LicNr === 'WEB999400' && cactusRailFtgKeyList.has(FtgKey))
}


export function validateTidomatFeaturesEnabled(LicNr) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return (LicNr === 'WEB117323');
}

export function validateRyskaPostenFeaturesEnabled(LicNr) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return LicNr === 'WEB051222';
}

export function validateSartoriusFeaturesEnabled(LicNr) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return LicNr === 'WEB032420';
}

export function validateOrangeCDFeaturesEnabled(LicNr) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return LicNr === 'WEB099823';
}

export function validateSverekFeaturesEnabled(LicNr, FtgKey) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return (LicNr === 'WEB999400' && sverekFtgKeyList.has(FtgKey));
}
export function validateSFStudiosProductionFeaturesEnabled(LicNr) {
	if(process.env.VUE_APP_DEBUG === 'true') {
		return true;
	}

	return (LicNr === 'WEB120923');
}

