import Vue           from 'vue';
import VueApexCharts from 'vue-apexcharts'
import vuetify       from './plugins/vuetify.js';
import App           from './App.vue';
import dv            from './plugins/dv.js';
import tabOrder      from './plugins/tabOrder.js';
import tryCatch      from './plugins/tryCatch.js';
import shortcuts     from './plugins/shortcuts.js';
import router        from './router/index.js';
import store         from './store/index.js';

Vue.config.productionTip = false;

Vue.use(dv);
Vue.use(tabOrder);
Vue.use(tryCatch);
Vue.use(shortcuts);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

new Vue({
	render: (h) => h(App),
	router,
	store,
	vuetify,
}).$mount('#app');
