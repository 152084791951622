<template>
	<div style="display: inline-block;">
		<input @click="reset" type="file" :multiple="false" ref="file" style="display: none;" :disabled="disabled" @change="onSelectFile">
		<div style="display: inline-block;" @click="open">
			<slot name="activator"></slot>
		</div>

		<div v-if="file && detachFileText">
			<v-btn @click.stop.prevent="reset" color="secondary" class="mt-2" outlined x-small text>{{detachFileText}}</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileUpload',

	props: {
		disabled: {
			type:    Boolean,
			default: false,
		},

		detachFileText: {
			type:    String,
			default: '',
		},
	},

	data: () => ({
		file: null,
	}),

	methods: {
		open() {
			this.$refs.file.click();
		},

		reset() {
			this.file = null;
			this.$refs.file.value = null;
			this.$emit('input', this.file);
			this.$emit('change', this.file);
		},

		onFocus() {
			if (!this.disabled) {
				this.$refs.fileInput.click();
			}
		},

		onSelectFile($event) {
			const files = $event.target.files || $event.dataTransfer.files;

			if (files && files.length === 1) {
				const file = files[0];

				const reader = new FileReader();

				reader.readAsDataURL(file);

				reader.onload = () => {
					const fileContentBase64 = reader.result;
					const fileName          = file.name;
					const parts             = fileName.split('.').reverse();
					const Format            = parts.shift();
					const Name              = parts.reverse().join('.');

					this.file = {
						name:            Name,
						size:            file.size,
						extension:       Format,
						content:         fileContentBase64.split(';base64,')[1],
						srcDataContent:  fileContentBase64,
						type:            file.type,
						originalFileSrc: file,
					};

					this.$emit('input', this.file);
					this.$emit('change', this.file);
				};

				reader.onerror = (error) => {
					this.$emit('error', error);
				};
			} else {
				this.file = null;
			}
		},
	},
}
</script>

<style scoped>

</style>
