<template>
	<h4 v-on="$listeners" :style="{'line-height': '1.4 !important', color: bold || $vuetify.theme.dark ? '' : '#828282'}" :class="{
		'text-center': centered,
		'display-1': large && !small,
		title: !large && !small,
		'font-weight-medium': bold,
		'subtitle-1': small,
	}
" class="font-weight-light text-truncate">
		<slot></slot>
	</h4>
</template>

<script>
export default {
	name: 'Title',

	props: {
		centered: {
			type:    Boolean,
			default: false,
		},

		bold: {
			type:    Boolean,
			default: false,
		},

		large: {
			type:    Boolean,
			default: false,
		},

		small: {
			type:    Boolean,
			default: false,
		},
	},
}
</script>

<style scoped>
	h4 {
		min-height: 21px;
	}
</style>
