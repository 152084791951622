<template>
	<div v-if="Authenticated">
		<template v-if="helpActive">
			<v-navigation-drawer :width="width" app right fixed v-if="helpActive" hide-overlay style="z-index: 999;">
				<v-app-bar fixed dense :height="143" flat style="margin-left: 1px;">
					<v-list dense style="width: 100%;">
						<v-list-item>
							<v-list-item-avatar>
								<v-icon color="accent" large class="ml-2">mdi-help-circle-outline</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title class="title">
									Hjälp
								</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-row no-gutters>
									<v-col>
										<Tooltip text="Utöka bredden på hjälp-dialogen">
											<v-btn icon @click="onWidthIconClick">
												<v-icon>mdi-arrow-expand-horizontal</v-icon>
											</v-btn>
										</Tooltip>
									</v-col>
									<v-col>
										<v-btn icon @click="setHelpActive(false)"><v-icon>mdi-close</v-icon></v-btn>
									</v-col>
								</v-row>
							</v-list-item-action>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-switch
									v-if="PortalInfo"
									dense
									hide-details
									label="Tillåt supportinloggning"
									color="accent"
									class="ml-2 font-weight-bold"
									:input-value="PortalInfo.EnableDVSupport"
									inset
									@change="onChangeEnableDVSupport"
									/>
							</v-list-item-content>
							<v-list-item-action>
								<v-row no-gutters>
									<v-col v-if="sendCompanyCopyEnabled">
										<Tooltip text="Skicka kopia av företag" direction="left">
											<v-btn color="accent" icon class="pa-0 ma-0" @click="onClickSendCompanyCopy"><v-icon>mdi-database-eye</v-icon></v-btn>
										</Tooltip>
									</v-col>
									<v-col>
										<Tooltip text="Ladda ned Crona Lön e-Assistans (TeamViewer)">
											<v-btn color="blue" icon class="pa-0 ma-0" @click="onClickTeamViewer"><v-icon>mdi-teamviewer</v-icon></v-btn>
										</Tooltip>
									</v-col>
								</v-row>
							</v-list-item-action>
						</v-list-item>

						<v-list-item>
							<v-list-item-content>
								<v-btn depressed x-small color="accent" href="https://crona.se/supportdokument-web" target="_blank">Till Supportdokument <v-icon right>mdi-open-in-new</v-icon></v-btn>
							</v-list-item-content>
						</v-list-item>
						<v-divider/>
					</v-list>
				</v-app-bar>


				<v-card flat :loading="isLoading" style="position: absolute; top: 143px; bottom: 0px; overflow-y: auto;">
					<v-card-title v-html="transformedTitle"></v-card-title>
					<v-card-text v-html="transformedHTML"></v-card-text>

					<v-card-text v-if="editMode">
						<v-divider></v-divider>
						<h1 class="subtitle-1 mt-1 text-center">Editeringsläge</h1>
						<v-text-field disabled label="ID" v-model="data.id"/>

						<v-text-field outlined label="Titel" v-model="data.title"/>
						<v-textarea outlined label="Text/HTML" v-model="data.html"></v-textarea>

						<v-list dense>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Fetstil</v-list-item-title>
									<v-list-item-subtitle>**Text**</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Kursiv</v-list-item-title>
									<v-list-item-subtitle>*Text*</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Video</v-list-item-title>
									<v-list-item-subtitle>*v*https://crona.se/film/FLMLON004/Stream*v*</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Länk</v-list-item-title>
									<v-list-item-subtitle v-text="linkExample"></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Supportdokument</v-list-item-title>
									<v-list-item-subtitle v-text="sdExample"></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Ikon</v-list-item-title>
									<v-list-item-subtitle>mdi-<i>IKONNAMN</i> (Exempel <i>mdi-delete</i> <v-icon small color="accent">mdi-delete</v-icon>)</v-list-item-subtitle>
									<v-list-item-subtitle><a href="https://materialdesignicons.com/" target="_blank">Ikonlista</a></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>

					<v-card-actions v-if="editMode">
						<v-spacer></v-spacer>
						<v-btn color="primary" @click="onSaveChanges">Spara</v-btn>
					</v-card-actions>
				</v-card>

			</v-navigation-drawer>
		</template>

		<template v-if="!helpActive">
			<v-navigation-drawer
							fixed
							width="35"
							right
							height="50"
							style="margin-top: 20%; border-bottom-left-radius: 1em; border-top-left-radius: 1em; cursor: pointer; z-index: 999;"
							floating
							class="elevation-2"
							hide-overlay
							mobile-breakpoint="0"
			>
				<v-icon color="accent" @click="setHelpActive(true)" style="margin-top: 10px; margin-left: 3px; font-size: 35px;">mdi-help-circle-outline</v-icon>
			</v-navigation-drawer>
		</template>

		<SendCompanyCopyDialog v-if="sendCompanyCopyEnabled" ref="sendCompanyCopyDialog"/>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Tooltip                from './Tooltip.vue';
import SendCompanyCopyDialog  from './SendCompanyCopyDialog.vue';

const transformInput = (input) => {
	let output = input.replace(/\n/g, '<br/>');

	output = output.replace(/mdi-([^\s<]+)/g, '<i class="v-icon notranslate mdi mdi-$1 theme--light accent--text"></i>')
	output = output.replace(/\*v\*([^*]+)\*v\*/, '<video width="320" height="240" controls><source src="$1"></source></video>');
	output = output.replace(/\*{2}([^*]+)\*{2}/g, '<b>$1</b>');
	output = output.replace(/\*([^*]+)\*/g, '<i>$1</i>');

	output = output.replace(/<a\s+href/g, '<a class="accent--text" target="_blank" href')
	output = output.replace(/<sd namn="([^"]+)">([^<]+)<\/sd>/g, (match, name, text) =>
		`<a class="accent--text" target="_blank" href="${process.env.VUE_APP_SD_BASE_URL}${name.toLowerCase()}">${text}</a>`
	);

	// https://crona.se/film/FLMLON004/Player
	return output;
};

const getPath = (path) => {
	const segments = path.split('/');
	const result = [];

	for(const segment of segments) {
		if(segment === '@' || segment.slice(0, 1) === ':') {
			break;
		}

		result.push(segment);
	}

	return result.join('/');
};

export default {
	name:       'AppHelp',
	components: {SendCompanyCopyDialog, Tooltip},
	data:       () => ({
		width:        400,
		isLoading:    true,
		helpActive:   false,
		data:         {},
		key:          null,
		fallbackData: {},
		cache:        {},
		linkExample:  `<a href="LÄNK">Text</a>`,
		sdExample:    '<sd namn="WEB0028">Text</sd>',
	}),

	mounted() {
		this.$eventBus.$on(`Help:SetActive`, () => this.setHelpActive(true), this);
		this.$eventBus.$on(`Help:SetInactive`, () => this.setHelpActive(false), this);
		this.$eventBus.$on(`Help:Toggle`, () => this.setHelpActive(!this.helpActive), this);
		this.$eventBus.$on(`Help:SetFromFieldFocus`, this.setFromFieldFocus, this);
		this.$eventBus.$on(`Help:SetFromHelpTextId`, this.setFromHelpTextId, this);
		this.$eventBus.$on('Help:SetFromCurrentView', this.setFromCurrentView, this);

		this.$router.afterEach(() => {
			this.setFromCurrentView();
		});
	},

	watch: {
		key() {
			this.loadData();
		},
		helpActive() {
			this.loadData();
		},
	},

	methods: {
		...mapActions(['confirmDialog', 'loadPortalInfo']),

		onClickSendCompanyCopy() {
			this.$refs.sendCompanyCopyDialog.show();
		},

		onClickTeamViewer() {
			this.confirmDialog({
				title:       'Crona Lön e-Assistans',
				text:        'Crona Software AB använder TeamViewer för e-Assistans. Genom att gå vidare kommer du att ladda ned klienten som möjliggör e-Assistans.',
				cancelText:  'Avbryt',
				confirmText: 'Fortsätt',
				onConfirm:   () => {
					this.$openNewTab('https://get.teamviewer.com/eassistans', {skipBaseURI: true});
				},
			})
		},

		onChangeEnableDVSupport(value) {
			this.confirmDialog({
				title:        'Tillåt supportinloggning',
				text:         `Är du säker på att du vill ${value === true ? 'tillåta supportinloggning' : 'stänga av supportinloggning'} för företaget <b>${this.$formatter.escape(this.FtgInfo.Namn)}</b>?<br/><br/>`,
				cancelText:   'Avbryt',
				confirmText:  value === true ? 'Tillåt' : 'Stäng av',
				confirmColor: value === true ? 'primary' : 'red',
				onConfirm:    () => {
					this.$tryCatch({
						task: async () => {
							await this.$http.put('/portalInfo', {
								EnableDVSupport: value,
							})

							await this.loadPortalInfo();
							this.$msgSuccess(value === true ? 'Supportinloggning är nu tillåtet.' : 'Supportinloggning har stängts av.')
						},
					});
				},
			});
		},

		onWidthIconClick() {
			this.width = this.width > 1000 ? 400 : Math.ceil(this.width * 1.4);
		},

		loadData() {
			if(!this.helpActive) {
				return;
			}

			if(this.key) {
				this.$tryCatch({
					task: async () => {
						this.isLoading = true;

						if(this.cache[this.key]) {
							this.data = Object.assign({}, this.cache[this.key]);
						} else {
							this.data            = await this.$http.get(`/helptexts/${encodeURIComponent(this.key)}`);
							this.cache[this.key] = Object.assign({}, this.data);
						}
					},

					error: () => {
						this.data = {};
					},

					finally: () => {
						if(!this.data.title && this.fallbackData) {
							this.data.title = this.fallbackData.title;
							this.data.html = this.fallbackData.html;
						}

						this.isLoading = false;
					},
				});
			} else {
				this.data = {};
			}
		},

		onSaveChanges() {
			this.$tryCatch({
				task: async () => {
					this.isLoading = true;

					await this.$http.put(`/helptexts/${encodeURIComponent(this.key)}`, this.data);

					//Reset cache
					this.cache[this.key] = null;

					this.$msgSuccess('Hjälptexten sparades.')
				},

				finally: () => {
					this.isLoading = false;
					this.loadData();
				},
			});
		},

		setHelpActive(value) {
			this.helpActive = value;
		},

		setFromCurrentView() {
			if(!this.$route || this.$route.matched.length === 0) {
				return;
			}

			const {name, matched: [{path}]} = this.$route;

			this.setCurrentHelpData(getPath(path).replace(/\//g, '-').slice(1), {
				title: name,
				html:  'Sidan saknar beskrivning',
			});
		},

		setFromHelpTextId(context) {
			if(!this.$route) {
				return;
			}

			const {id, title} = context;
			const {matched: [{path}]} = this.$route;

			const key = (context.ignorePath ? id : [getPath(path), id].join('-')).replace(/\//g, '-').slice(context.ignorePath ? 0 : 1);

			this.setCurrentHelpData(key, {
				title: title || '',
				html:  'Saknas beskrivning.',
			});
		},

		setFromFieldFocus(context) {
			if(!this.$route || this.$route.matched.length === 0) {
				return;
			}

			const {model, name} = context;
			const {matched: [{path}]} = this.$route;

			if(!model || !name || !model.constructor.propertyMap || !model.constructor.propertyMap[name]) {
				this.data.title = '';
				this.data.html = 'Fältet saknar beskrivning.';
				this.fallbackData = {};
				this.key = null;

				return;
			}

			if(model.constructor.propertyMap[name].helpTextId) {
				this.setFromHelpTextId({id: model.constructor.propertyMap[name].helpTextId, ignorePath: model.constructor.propertyMap[name].helpTextIdIgnorePath, title: model.constructor.propertyMap[name].label});

				return;
			}

			this.setCurrentHelpData([getPath(path), name].join('-').replace(/\//g, '-').slice(1), {
				title: model.constructor.propertyMap[name].label,
				html:  'Fältet saknar beskrivning',
			});
		},

		//Using timeout to prevent race-conditions between 'setFromCurrentView' and 'setFromFocusField' which could occur during autofocus property on some fields
		setCurrentHelpData(key, fallbackData) {
			clearTimeout(this.setCurrentHelpDataTimer);

			this.setCurrentHelpDataTimer = setTimeout(() => {
				this.key = key;
				this.fallbackData = fallbackData;
			}, 100);
		},
	},

	computed: {
		...mapState(['Authenticated', 'PortalInfo', 'FtgInfo', 'UserAccount', 'UserRights']),

		sendCompanyCopyEnabled() {
			return process.env.VUE_APP_SEND_COMPANY_COPY_ENABLED === 'true' &&
						!this.UserAccount.Login.toLowerCase().includes('dvsupport') &&
						this.UserRights.hasSendCompanyCopyAccess();
		},

		editMode() {
			return [this.UserAccount.LicNr, '*'].includes(process.env.VUE_APP_ENABLE_HELPTEXT_EDIT_ACCESSIBLE_FOR_LICNR);
		},

		transformedTitle() {
			if(!this.data.title) {
				return '';
			}

			return transformInput(this.data.title);
		},

		transformedHTML() {
			if(!this.data.html) {
				return '';
			}

			return transformInput(this.data.html);
		},
	},
}
</script>

<style scoped>
	::v-deep(.v-toolbar__content) {
		padding: 0;
	}

	::v-deep(.v-navigation-drawer__content) {
		overflow-y: hidden;
	}
</style>
