<template>
	<v-slide-x-transition hide-on-leave>
		<v-text-field
						v-model="model[name]"
						:rules="model.$rules[name]"
						v-bind="$attrs"
						v-on="$listeners"
						:type="showPlain ? 'text' : 'password'"
						:label="noLabel ? '' : model.constructor.propertyMap[name].label"
						@keydown="$emit('keydown', $event)"
						:data-name="name"
						:data-tabordername="tabOrderName || name"
						@keyup="$emit('keyup', $event)"
						@keydown.native="$checkForTabOrderEvent($event)"
						:append-icon="showPlain ? 'mdi-eye-off' : 'mdi-eye'"
						@click:append="showPlain = !showPlain"
						ref="field"
						validate-on-blur
						@focus="onFocus($event)"
						@blur="onBlur($event)"
						:counter="fieldCounter"
						autocomplete="off"
						color="accent"
						persistent-placeholder
		></v-text-field>
	</v-slide-x-transition>
</template>

<script>
export default {
	name: 'PasswordField',

	props: {
		model: {
			required: true,
		},
		name: {
			required: true,
		},
		tabOrderName: {
			type:    String,
			default: '',
		},
		noLabel: {
			type:    Boolean,
			default: false,
		},
		counter: {
			default: false,
		},
	},

	data: () => ({
		showPlain:    false,
		fieldCounter: false,
	}),

	methods: {
		focus() {
			this.$refs.field.focus();
		},

		onFocus() {
			this.$eventBus.$emit('Help:SetFromFieldFocus', {
				model: this.model,
				name:  this.name,
			});

			// this.$emit('focus', event);

			this.fieldCounter = this.counter;
		},

		onBlur() {
			this.resetValidation();

			// this.$emit('blur', event);

			this.fieldCounter = false;
		},

		setShowPlain(value) {
			this.showPlain = value;
		},

		resetValidation() {
			this.$refs.field.resetValidation();
		},
	},
}
</script>

<style scoped>

</style>
