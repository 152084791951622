<template>
	<Dialog title="Rensa gamla löneår" ref="dialog" :persistent="isDeleting" :width="800">
		<v-row dense justify="center">
			<v-col cols="12">
				<p>
					Här har du möjlighet att rensa gamla löneår. Programmet kommer ta bort all information fr.o.m första löneåret som finns tillgängligt fram t.o.m markerat löneår, inklusive lönebesked, bokföring, tidrader, m.m.
				</p>
				<p>
					För din egen säkerhet har du inte möjlighet att rensa de senaste två löneåren som är skapade i företaget. Detta för att beräkningar som kan omfatta två löneår ska bli korrekt, t.e.x semesterberedning.
				</p>
			</v-col>

			<v-col cols="12">
				<SelectField
					:disabled="isDeleting"
					:model="model"
					ref="Year"
					name="Year"
					:items="availableYears"
					item-value="Year"
					item-text="Year"
				/>
			</v-col>

			<v-col cols="12">
				<v-alert border="top" colored-border type="warning" elevation="1" class="ma-0 caption" dense>
					Tänk på att all information som tillhör markerade löneår kommer att försvinna för alltid. Det finns ingen möjlighet till att återställa informationen i efterhand.
				</v-alert>
			</v-col>
		</v-row>

		<template v-slot:buttons="{hide}">
			<v-btn color="secondary" @click="hide" :disabled="isDeleting">Avbryt</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="error" @click="onConfirm" :disabled="!model.Year" :loading="isDeleting">{{model.Year ? 'Rensa' : 'Det finns inga löneår att rensa'}}</v-btn>
		</template>
	</Dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import BaseModel              from '../models/BaseModel.js';
import Dialog                 from './Dialog.vue';
import SelectField            from './form/SelectField.vue';

class Year extends BaseModel {
	static get propertyMap() {
		return {
			Year: {
				label:        'Rensa löneår fram t.o.m',
				type:         String,
				defaultValue: '',
				rules:        [],
			},
		};
	}
}

export default {
	name: 'DeleteSalaryYearsDialog',

	data: () => ({
		isDeleting: false,
		model:      new Year(),
	}),

	methods: {
		...mapActions(['confirmDialog', 'refreshSalaryYears']),

		show() {
			this.$refs.dialog.show();
			this.model = new Year({
				Year: this.availableYears.length > 0 ? this.availableYears[0] : '',
			});
		},

		onConfirm() {
			this.confirmDialog({
				title:        'Rensa gamla löneår',
				text:         `Är du helt säker på att du vill rensa gamla löneår fram <b>t.o.m ${this.model.Year}</b>? Åtgärden kan <b>inte</b> backas.`,
				cancelText:   'Avbryt',
				confirmText:  'Ja, rensa',
				confirmColor: 'red',
				onConfirm:    () => {
					this.$tryCatch({
						task: async () => {
							this.isDeleting = true;

							//+1 Because endpoint deletes < selectedYear
							await this.$http.delete(`/salaryyears/${encodeURIComponent(parseInt(this.model.Year, 10) + 1)}`);

							this.refreshSalaryYears();

							this.$msgSuccess('Rensning slutförd.');

							this.$refs.dialog.hide();
						},

						finally: () => {
							this.isDeleting = false;
						},
					});
				},
			});
		},
	},

	computed: {
		...mapState(['SalaryYears']),

		availableYears() {
			const years = [...this.SalaryYears];

			//Remove last 2 years
			years.shift();
			years.shift();

			return years.reverse();
		},
	},

	components: {SelectField, Dialog},
}
</script>

<style scoped>

</style>
