<template>
	<Dialog ref="dialog" title="Licensinformation" :width="1000">

		<Loading :visible="true" v-if="isLoading"/>

		<v-row v-if="licenseInfo">

			<v-col cols="6">
				<v-list dense>
					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Licensnummer</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small><Tooltip v-if="clipboardAPIEnabled" text="Kopiera"><v-btn icon small @click="copyTextToClipboard(licenseInfo.LicNr)"><v-icon small color="accent">mdi-content-copy</v-icon></v-btn></Tooltip> {{licenseInfo.LicNr}}</SubTitle></v-list-item-action>
					</v-list-item>

					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Licens giltig t.o.m</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{licenseInfo.LicDate === '2059-12-31' ? 'Tillsvidare' : licenseInfo.LicDate}}</SubTitle></v-list-item-action>
					</v-list-item>
				</v-list>
				<v-divider/>

				<v-list dense>
					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Organisationsnummer</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{licenseInfo.Orgnr}}</SubTitle></v-list-item-action>
					</v-list-item>

					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Företag</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{licenseInfo.FtgNamn}}</SubTitle></v-list-item-action>
					</v-list-item>

					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Postadress</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{[licenseInfo.PostAdr || licenseInfo.GatuAdr, licenseInfo.PostNr, licenseInfo.PostOrt].filter((v) => v).join(', ')}}</SubTitle></v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>

			<v-col cols="6">
				<v-list dense>
					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Modell</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{licenseInfo.Modell === '90' ? `${SupplierInfo.SoftwareName} Plus` : `${SupplierInfo.SoftwareName} Bas`}}</SubTitle></v-list-item-action>
					</v-list-item>

					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Antal företag</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{licenseInfo.Statistik.CurrentForetag}}</SubTitle></v-list-item-action>
					</v-list-item>
				</v-list>

				<v-divider/>

				<v-list dense>
					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>E-post</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{licenseInfo.Epost}}</SubTitle></v-list-item-action>
					</v-list-item>

					<v-list-item class="pl-0">
						<v-list-item-content>
							<v-list-item-title><Title small>Telefon</Title></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action><SubTitle small>{{licenseInfo.Telefon}}</SubTitle></v-list-item-action>
					</v-list-item>


				</v-list>
			</v-col>

			<template  v-if="licenseInfo.Statistik.LicensOption.length > 0">

				<v-col cols="12" class="pl-2">
					<Title centered>Tillägg</Title>
					<v-card outlined>
						<v-card-text class="pa-0 ma-0">
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
									<tr>
										<th>Namn</th>
										<th class="text-right">Aktiva</th>
										<th class="text-right">Max antal</th>
									</tr>
									</thead>
									<tbody>
									<tr v-for="(item, index) in licenseInfo.Statistik.LicensOption" :key="index">
										<td>{{item.Text}}</td>
										<td class="text-right">{{item.MaxUsers > 0 ? item.CurrentUsers : ''}}</td>
										<td class="text-right">{{item.MaxUsers > 0 ? item.MaxUsers : ''}}</td>
									</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
			</template>
		</v-row>

		<template v-slot:buttons="{}">
			<v-spacer/>
			<v-btn color="secondary" @click="$refs.dialog.hide()">Stäng</v-btn>
			<v-spacer/>
		</template>
	</Dialog>
</template>

<script>
import {mapState} from 'vuex';
import Dialog     from './Dialog.vue';
import Title      from './typography/Title.vue';
import SubTitle   from './typography/SubTitle.vue';
import Tooltip    from './Tooltip.vue';
import Loading    from './Loading.vue';

export default {
	name: 'LicensInfoDialog',

	data: () => ({
		isLoading:   false,
		licenseInfo: null,
	}),

	methods: {
		show() {
			if(this.isLoading) {
				return;
			}

			this.$refs.dialog.show();

			this.$tryCatch({
				task: async () => {
					this.isLoading = true;

					const {LicenseInfo} = await this.$http.get('/licenseInformation');

					this.licenseInfo = LicenseInfo;
				},

				finally: () => {
					this.isLoading = false;
				},

				error: () => {
					this.$refs.dialog.hide();
				},
			});
		},
	},

	computed: {
		...mapState(['UserAccount', 'SupplierInfo']),
	},

	components: {Loading, Tooltip, SubTitle, Title, Dialog},
}
</script>

<style scoped>

</style>
