<template>
	<div>
		<v-hover>
			<v-navigation-drawer
				v-model="drawer"
				:clipped="useClippedNavLayout"
				app
				slot-scope="{hover}"
				:mini-variant="isMini && !hover"
				:width="210"
				:mini-variant-width="60"
				:permanent="$vuetify.breakpoint.lgAndUp"
			>
				<v-list dense class="mt-0 pt-0" :class="{'is-expanded': !isMini || hover}">
					<v-list-item style="min-height: 53px;" class="mt-0 pl-0" @click="canSelectCompany ? onClickSelectCompany() : undefined" :inactive="!canSelectCompany" :ripple="canSelectCompany">
						<v-list-item-avatar class="pa-0 mt-0 mb-0 ml-2" tile :class="{'ml-5': isMini && !hover, 'pl-3': isMini && !hover}">
							<v-icon :color="navIconColor">mdi-domain</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>{{FtgInfo.Namn}}</v-list-item-title>
							<v-list-item-subtitle>{{$formatter.formatPersNrOrgNrTo12(FtgInfo.Orgnr, {dash: true, serialNr: false}).slice(2)}}</v-list-item-subtitle>
						</v-list-item-content>

					</v-list-item>
					<v-divider></v-divider>

					<template v-for="item in navigationItems">
						<v-divider v-if="item.divideBefore"></v-divider>

						<v-list-group
							v-if="item.children"
							:key="item.text"
							:append-icon="''"
							:class="{'listgroupmini': isMini && !hover}"
							:color="'inherit'"
						>
							<template slot="activator">
								<v-list-item class="mt-0 pl-0">
									<v-list-item-avatar tile class="pa-0 mt-0 mb-0 ml-2" :class="{'ml-5': isMini && !hover, 'pl-3': isMini && !hover}">
										<v-icon v-if="item.icon" :color="navIconColor">{{item.icon}}</v-icon>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title :to="item.to" v-show="!isMini || hover">
											{{ item.text }}
										</v-list-item-title>

									</v-list-item-content>
								</v-list-item>

							</template>
							<template v-for="(child) in item.children">
								<template>
									<v-divider v-if="child.divideBefore && (!isMini || hover)"></v-divider>
									<v-list-item
										class="reduced-min-height"
										v-show="!isMini || hover"
										:to="child.to"
									>
										<v-list-item-action>
											<v-icon v-if="child.icon">{{ child.icon }}</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>
												{{ child.text }}
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider v-if="child.divideAfter && (!isMini || hover)"></v-divider>
								</template>
							</template>
						</v-list-group>

						<v-list-item  class="mt-0 pl-0" v-if="(!item.children || item.children.length === 0) && !item.onClick" :key="item.text" :to="item.to">
							<v-list-item-avatar class="pa-0 mt-0 mb-0 ml-2" tile :class="{'ml-5': isMini && !hover, 'pl-3': isMini && !hover}">
								<v-icon v-if="item.icon" :color="navIconColor">{{item.icon}}</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title v-show="!isMini || hover">
									{{ item.text }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item  class="mt-0 pl-0" v-if="(!item.children || item.children.length === 0) && item.onClick" :key="item.text" @click="item.onClick">
							<v-list-item-avatar class="pa-0 mt-0 mb-0 ml-2" tile :class="{'ml-5': isMini && !hover, 'pl-3': isMini && !hover}">
								<v-icon v-if="item.icon" :color="navIconColor">{{item.icon}}</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title v-show="!isMini || hover">
									{{ item.text }}
								</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action v-if="!isMini || hover">
								<v-icon small>mdi-open-in-new</v-icon>
							</v-list-item-action>
						</v-list-item>

					</template>
				</v-list>
			</v-navigation-drawer>
		</v-hover>

		<SelectCompanyDialog ref="selectCompanyDialog" @cancel="$refs.selectCompanyDialog.hide()" @change="$refs.selectCompanyDialog.hide()" :showOnMounted="false"/>
	</div>
</template>

<script>
import {mapState}                                                      from 'vuex';
import LocalStorage                                                    from '../lib/LocalStorage.js';
import {PendingUserRights}                                             from '../../shared/userRights.js';
import {validateGalenicaFeaturesEnabled, validateMazarsReportsEnabled} from '../lib/helpers/validateCustomerCustomFnEnabled.js';
import SelectCompanyDialog                                             from './SelectCompanyDialog.vue';

export default {
	name:       'AppNavigation',
	components: {SelectCompanyDialog},
	watch:      {
		mini() {
			LocalStorage.set('AppNavigationMini', this.mini);
		},

		drawer() {
			LocalStorage.set('AppNavigationDrawer', this.drawer);
		},
	},
	data() {
		return {
			drawer:              LocalStorage.get('AppNavigationDrawer', true),
			mini:                LocalStorage.get('AppNavigationMini', this.$vuetify.breakpoint.lgAndUp),
			useClippedNavLayout: true,
			navIconColor:        '',
			navigationItems:     [],
		};
	},

	methods: {
		onClickSelectCompany() {
			this.$refs.selectCompanyDialog.show();
		},

		setNavigationItems() {
			const {HasPlus, userLSSEnabled, UserAccount, HasUserOption, HasLicensOption, LicenseSupplier} = this;
			const {HRTIL} = HasLicensOption;

			const Roll = UserAccount ? UserAccount.Roll : '';

			const {
				AmfTecknad,
				ITPTecknad,
				ScbStatistik,
				SafMedlem,
				KfoMedlem,
				KPTecknad,
				UseLosen,
				OpenResEnhet1,
				OpenResEnhet2,
				OpenResEnhet3,
				OpenResEnhet4,
				OpenResEnhet5,
				OpenResEnhet6,
				RegisterResEnh1,
				RegisterResEnh2,
				RegisterResEnh3,
				RegisterResEnh4,
				RegisterResEnh5,
				RegisterResEnh6,
				UseAvdelning,
			} = this.FtgInfo;

			this.navigationItems = this.UserRights instanceof PendingUserRights ? [] : [
				{
					text: 'Informationspanel',
					icon: 'mdi-bullhorn',
					to:   '/infopanel',
				},
				{
					text:        'Kalendarie',
					icon:        'mdi-calendar-month',
					to:          '/kalendarie',
					rightsCheck: () => this.UserRights.hasKalendarieReadAccess(),
				},
				{
					text:        'Tidrapport',
					icon:        'mdi-clock',
					to:          '/tidrapport',
					rightsCheck: () => this.UserRights.hasTidrapportReadAccess({HasPlus}),
				},
				{
					text:        'Ersättningar',
					icon:        'mdi-credit-card',
					to:          '/ersattningar',
					rightsCheck: () => this.UserRights.hasErsattningarReadAccess(),
				},
				{
					text:     'Lönekörning',
					icon:     'mdi-sack',
					children: [
						{
							text:        'FOS',
							to:          '/fos',
							rightsCheck: () => this.UserRights.hasFOSReadAccess(),
						},
						{
							text:        'Lönebesked',
							to:          '/payslips',
							rightsCheck: () => this.UserRights.hasPayslipsReadAccess(),
						},
						{
							text:        'Arbetsgivardeklaration',
							to:          '/employerDeclaration',
							rightsCheck: () => this.UserRights.hasEmployerDeclarationReadAccess(),
						},
						{
							text:        'Bokföringsunderlag',
							to:          '/bokforingsunderlag',
							rightsCheck: () => this.UserRights.hasBookkeepingReadAccess(),
						},
					],
				},
				{
					text:     'Funktioner',
					icon:     'mdi-calculator-variant',
					children: [
						{
							text:        'Löneuppdrag',
							to:          '/loneuppdrag',
							rightsCheck: () => this.UserRights.hasLoneuppdragReadAccess(),
						},
						{
							text:        'Analys',
							to:          '/datatreesearch',
							rightsCheck: () => this.UserRights.hasDataTreeSearchAccess(),
						},
						{
							text:        'Semesterberedning',
							to:          '/semberedning',
							rightsCheck: () => this.UserRights.hasSemBerReadAccess({HasPlus}),
						},
						{
							text:        'Fora',
							to:          '/fora',
							rightsCheck: () => this.UserRights.hasForaReadAccess({AmfTecknad}),
						},
						{
							text:        'Collectum',
							to:          '/collectum',
							rightsCheck: () => this.UserRights.hasCollectumReadAccess({HasPlus, ITPTecknad}),
						},
						{
							text:        'SCB',
							to:          '/scb',
							rightsCheck: () => this.UserRights.hasSCBReadAccess({HasPlus, ScbStatistik}),
						},
						{
							text:        'Kontrolluppgift',
							to:          '/kontrolluppgift',
							rightsCheck: () => this.UserRights.hasKUBerReadAccess({HasPlus}),
						},
						{
							text:        'Ferielön',
							to:          '/ferLon',
							rightsCheck: () => this.UserRights.hasFerLonReadAccess({HasUserOption}) && !!this.AgreementsList.find(({FerIngBer}) => FerIngBer),
						},
						{
							text:        'Uppehållslön',
							to:          '/uphLon',
							rightsCheck: () => this.UserRights.hasUphLonReadAccess({HasUserOption}) && !!this.AgreementsList.find(({FerUphLon}) => FerUphLon),
						},
						{
							text:        'Svenskt Näringsliv',
							to:          '/saf',
							rightsCheck: () => this.UserRights.hasSAFReadAccess({HasPlus, SafMedlem}),
						},
						{
							text:        'Fremia',
							to:          '/kfo',
							rightsCheck: () => this.UserRights.hasKFOReadAccess({HasPlus, KfoMedlem}),
						},
						{
							text:        'Pensionsvalet',
							to:          '/kpber',
							rightsCheck: () => this.UserRights.hasKPBerReadAccess({HasPlus, KPTecknad}),
						},
						{
							text:        'LÖSEN',
							to:          '/losen',
							rightsCheck: () => this.UserRights.hasLOSENReadAccess({HasPlus, UseLosen}),
						},
						{
							text:         'Förskottsutbetalning',
							to:           '/forskottutbetalning',
							divideBefore: true,
							rightsCheck:  () => this.UserRights.hasForskUtbWriteAccess({HasPlus}),
						},
						{
							text:        'Lönerevision',
							to:          '/lonerevision',
							rightsCheck: () => this.UserRights.hasLoneRevisionReadAccess({HasPlus}),
						},
						{
							text:        'Lönekartläggning',
							to:          '/lonekartlaggning',
							rightsCheck: () => this.UserRights.hasLonekartlaggningAccess({HasPlus}),
						},
						{
							text:        'Retroaktiv lön',
							to:          '/retrolon',
							rightsCheck: () => this.UserRights.hasRetroLonReadAccess({HasPlus}),
						},
						{
							text:        'Sjukanmälan FK',
							to:          '/fksjukanmalan',
							rightsCheck: () => this.UserRights.hasFKSjukanmalanAccess(),
						},
						{
							text:         'Närvarostatus',
							to:           '/narvarostatus',
							rightsCheck:  () => this.UserRights.hasNarvarostatusAccess(),
							divideBefore: true,
						},
						{
							text:        'Dokument',
							to:          '/documents',
							rightsCheck: () => this.UserRights.hasDocumentsReadAccess({HasPlus}),
						},
					],
				},
				{
					text:     'LSS Assistansersättning',
					icon:     'mdi-human-wheelchair',
					children: [
						{
							text:        'Rapportering omkostnader',
							to:          '/lss/rapOmk',
							rightsCheck: () => this.UserRights.hasLSSRapOmkReadAccess({userLSSEnabled}),
						},
						{
							text:        'Beredning',
							to:          '/lss/overview',
							rightsCheck: () => this.UserRights.hasLSSBerReadAccess({userLSSEnabled}),
						},
						{
							text:        'Rapportering ersättning',
							to:          '/lss/rapErs',
							rightsCheck: () => this.UserRights.hasLSSRapErsReadAccess({userLSSEnabled}),
						},
						{
							text:        'Redovisning',
							to:          '/lss/redov',
							rightsCheck: () => this.UserRights.hasLSSRedovReadAccess({userLSSEnabled}),
						},
						{
							text:        'Återrapportering',
							to:          '/lss/aterrapportering',
							rightsCheck: () => this.UserRights.hasLSSAterrapporteringReadAccess({userLSSEnabled}),
						},
						{
							text:         'Kostnadstyper',
							to:           '/lss/kostntyp',
							divideBefore: true,
							rightsCheck:  () => this.UserRights.hasLSSKostnTypReadAccess({userLSSEnabled}),
						},
						{
							text:        'Inställningar',
							to:          '/lss/settings',
							rightsCheck: () => this.UserRights.hasLSSSettingsReadAccess({userLSSEnabled}),
						},
					],
				},
				{
					text:     'Rapporter',
					icon:     'mdi-note-text',
					children: [
						{
							text:        'Debiteringskonto',
							to:          '/rapporter/debiteringskonto',
							rightsCheck: () => this.UserRights.hasDebKontoReportAccess({Roll}),
						},
						{
							text:        'Bruttolönelista',
							to:          '/rapporter/bruttolonelista',
							rightsCheck: () => this.UserRights.hasBruttoloneListaReportAccess(),
						},
						{
							text:        'Lönestatistik',
							to:          '/rapporter/lonestatistik',
							rightsCheck: () => this.UserRights.hasLonestatistikReportAccess(),
						},
						{
							text:        'Löneartslista',
							to:          '/rapporter/loneartslista',
							rightsCheck: () => this.UserRights.hasLoneartslistaReportAccess({HasPlus}),
						},
						{
							text:        'Resultatrapport',
							to:          '/rapporter/resultatrapport',
							rightsCheck: () => this.UserRights.hasResultatrapportReportAccess(),
						},
						{
							text:        'Saldoskuld',
							to:          '/rapporter/saldoskuld',
							rightsCheck: () => this.UserRights.hasSaldoskuldReportAccess({HasPlus}),
						},
						{
							text:         'Avtalslista',
							to:           '/rapporter/avtalslista',
							divideBefore: true,
							rightsCheck:  () => this.UserRights.hasAgreementsReadAccess(),
						},
						{
							text:         'Personallista',
							to:           '/rapporter/personallista',
							divideBefore: true,
							divideAfter:  !this.UserRights.hasPerRegReportAccess(),
							rightsCheck:  () => this.UserRights.hasPerListaReportAccess(),
						},
						{
							text:         'Personkort',
							to:           '/rapporter/personkort',
							divideBefore: !this.UserRights.hasPerListaReportAccess(),
							rightsCheck:  () => this.UserRights.hasPerRegReportAccess(),
						},
						{
							text:        'FTE & Headcount',
							to:          '/rapporter/fteHeadcount',
							rightsCheck: () => this.UserRights.hasFTEHeadcountReportAccess({HasUserOption}),
						},
						{
							text:        'Kontaktpersoner',
							to:          '/rapporter/perkontakt',
							divideAfter: true,
							rightsCheck: () => this.UserRights.hasPerKontaktReportAccess(),
						},
						{
							text:        this.$formatter.brukareNamnTypText('Brukarlista'),
							to:          '/rapporter/brukarreglista',
							rightsCheck: () => this.UserRights.hasBrukarListaReportAccess({userLSSEnabled}),
						},
						{
							text:        'Assistentlista',
							to:          '/rapporter/brukarperlista',
							divideAfter: true,
							rightsCheck: () => this.UserRights.hasBrukarListaReportAccess({userLSSEnabled}),
						},
						{
							text:        'LAS-lista',
							to:          '/rapporter/laslista',
							rightsCheck: () => this.UserRights.hasLASListaReportAccess({HasPlus}),
						},
						{
							text:        'Rehablista',
							to:          '/rapporter/rehablista',
							rightsCheck: () => this.UserRights.hasRehablistaReportAccess({HasPlus}),
						},
						{
							text:        'Tidsjournaler',
							to:          '/rapporter/timeJournals',
							rightsCheck: () => this.UserRights.hasTimeJournalsReportAccess({HasPlus}),
						},
						{
							text:        'Löneutmätning',
							to:          '/rapporter/loneutmatning',
							rightsCheck: () => this.UserRights.hasLoneutmatningReportAccess(),
						},
						{
							text:        'Löneutveckling',
							to:          '/rapporter/loneutveckling',
							rightsCheck: () => this.UserRights.hasLoneutvecklingReportAccess({HasPlus}),
						},
						{
							text:        'Fackavgifter',
							to:          '/rapporter/fackavgifter',
							rightsCheck: () => this.UserRights.hasFackAvgReportAccess({HasPlus}),
						},
						{
							text:         'Galenica Nyckeltal',
							to:           '/rapporter/custom/galenicaKeyFiguresReport',
							divideBefore: true,
							rightsCheck:  () => this.FtgInfo && validateGalenicaFeaturesEnabled(this.UserAccount.LicNr, this.FtgInfo.FtgKey),
						},
						{
							text:         'Mazars Special',
							to:           '/rapporter/custom/mazarsSpecialReports',
							divideBefore: true,
							rightsCheck:  () => validateMazarsReportsEnabled(this.UserAccount.LicNr),
						},
					],
				},
				{
					text:     'Register',
					icon:     'mdi-notebook',
					children: [
						{
							text:        'Personal',
							to:          '/registry/employees',
							rightsCheck: () => this.UserRights.hasPerRegReadAccess(),
						},
						{
							text:        this.$formatter.brukareNamnTypText('BrukarePlural'),
							to:          '/registry/brukare',
							rightsCheck: () => this.UserRights.hasBrukarRegReadAccess({userLSSEnabled}),
						},
						{
							text:        'Arbetstidsschema',
							to:          '/registry/arbschema',
							rightsCheck: () => this.UserRights.hasArbSchemaReadAccess(),
						},
						{
							text:        'Dagschema',
							to:          '/registry/dagschema',
							rightsCheck: () => this.UserRights.hasDagSchemaReadAccess({HasPlus}),
						},
						{
							text:        'OB-Regler',
							to:          '/registry/obregler',
							rightsCheck: () => this.UserRights.hasOBReglerReadAccess({HasPlus}) && !!this.AgreementsList.find(({OpenOBRegler}) => OpenOBRegler),
						},
						{
							text:        'Övertidsregler',
							to:          '/registry/ovtregler',
							rightsCheck: () => this.UserRights.hasOvtReglerReadAccess({HasPlus}) && !!this.AgreementsList.find(({OpenOVTRegler}) => OpenOVTRegler),
						},
						{
							text:        'Jourtidsregler',
							to:          '/registry/jorregler',
							rightsCheck: () => this.UserRights.hasJorReglerReadAccess({HasPlus}) && !!this.AgreementsList.find(({OpenJORRegler}) => OpenJORRegler),
						},
						{
							text:        'Beredskapstidsregler',
							to:          '/registry/berregler',
							rightsCheck: () => this.UserRights.hasBerReglerReadAccess({HasPlus}) && !!this.AgreementsList.find(({OpenBERRegler}) => OpenBERRegler),
						},
						{
							text:        'Extra ledighetsregler',
							to:          '/registry/eldregler',
							rightsCheck: () => this.UserRights.hasEldReglerReadAccess({HasPlus}) && !!this.AgreementsList.find(({OpenELDRegler}) => OpenELDRegler),
						},
						{
							text:        'Helglöneregler',
							to:          '/registry/hlgregler',
							rightsCheck: () => this.UserRights.hasHlgReglerReadAccess({HasPlus}) && !!this.AgreementsList.find(({OpenHLGRegler}) => OpenHLGRegler),
						},
						{
							text:        'Avtal',
							to:          '/registry/agreements',
							rightsCheck: () => this.UserRights.hasAgreementsReadAccess(),
						},
						{
							text:        'Semesteravtal',
							to:          '/registry/vacationagreements',
							rightsCheck: () => this.UserRights.hasVacationAgreementsReadAccess({HasPlus}),
						},

						{
							text:         'Lönearter',
							to:           '/registry/artreg',
							divideBefore: true,
							rightsCheck:  () => this.UserRights.hasArtRegReadAccess(),
						},
						{
							text:        'Löneartstabeller',
							to:          '/registry/loneartstabeller',
							rightsCheck: () => this.UserRights.hasArtRegReadAccess(),
						},
						{
							text:        'Löneartsstyrning',
							to:          '/registry/artsty',
							rightsCheck: () => this.UserRights.hasArtRegReadAccess(),
						},
						{
							text:        'Formelvariabler',
							to:          '/registry/customFormelVariables',
							rightsCheck: () => this.UserRights.hasCustomFormelVariablesReadAccess(),
						},
						{
							text:        'Lönekoder',
							to:          '/registry/lonkoder',
							rightsCheck: () => this.UserRights.hasLonKoderReadAccess(),
						},
						{
							text:        'Tidkoder',
							to:          '/registry/tidkoder',
							rightsCheck: () => this.UserRights.hasTidKoderReadAccess(),
						},
						{
							text:        'Importuppdrag försystem',
							to:          '/registry/impreg',
							rightsCheck: () => this.UserRights.hasImpRegReadAccess(),
						},
						{
							text:         'Konton',
							to:           '/registry/accounts',
							divideBefore: true,
							rightsCheck:  () => this.UserRights.hasKontoRegReadAccess(),
						},
						{
							text:        'Resultatenheter',
							to:          '/registry/resenheter',
							rightsCheck: () => this.UserRights.hasResEnhReadAccess({
								OpenResEnhet1,
								OpenResEnhet2,
								OpenResEnhet3,
								OpenResEnhet4,
								OpenResEnhet5,
								OpenResEnhet6,
								RegisterResEnh1,
								RegisterResEnh2,
								RegisterResEnh3,
								RegisterResEnh4,
								RegisterResEnh5,
								RegisterResEnh6,
							}),
						},
						{
							text:        'Fördelningsnycklar',
							to:          '/registry/fordelningsnycklar',
							rightsCheck: () => this.UserRights.hasFordelningsnycklarReadAccess({HasPlus}),
						},
						{
							text:        'Ackumulatorer',
							to:          '/registry/friaackardef',
							rightsCheck: () => this.UserRights.hasFriaAckarDefReadAccess({HasPlus}),
						},
						{
							text:        'Egenskaper',
							to:          '/registry/egenskaper',
							rightsCheck: () => this.UserRights.hasEgenskaperReadAccess({HasPlus}),
						},
						{
							text:        'Varugrupper',
							to:          '/registry/varugrupper',
							divideAfter: true,
							rightsCheck: () => this.UserRights.hasVarugrupperReadAccess(),
						},
						{
							text:        'Avdelningar',
							to:          '/registry/departments',
							rightsCheck: () => this.UserRights.hasDepartmentsReadAccess({UseAvdelning}),
						},
						{
							text:        'Fackföreningar',
							to:          '/registry/fackfor',
							rightsCheck: () => this.UserRights.hasFackForReadAccess({HasPlus}),
						},
						{
							text:        'Kalender',
							to:          '/registry/holidays',
							rightsCheck: () => this.UserRights.hasHolidaysReadAccess(),
						},
						{
							text:        'Perioder',
							to:          '/registry/salaryperiods',
							rightsCheck: () => this.UserRights.hasSalaryPeriodsReadAccess(),
						},
					],
				},

				{
					text:        'Crona HR',
					icon:        'mdi-lightbulb-on',
					to:          '/cronahr',
					// onClick: () => {
					// 	this.$openNewTab('https://crona.se/crona-hr', {skipBaseURI: true});
					// },
					rightsCheck: () => process.env.VUE_APP_DEBUG === 'true' ? true : !!(HasPlus && !HRTIL) && LicenseSupplier === 'CRONA',
				},

				{
					text:     'Inställningar',
					icon:     'mdi-cog',
					children: [
						{
							text:        'Företag',
							to:          '/settings/company',
							rightsCheck: () => this.UserRights.hasCompanySettingsReadAccess(),
						},
						{
							text:        'Bokföring',
							to:          '/settings/bokforing',
							rightsCheck: () => this.UserRights.hasBokforingSettingsReadAccess(),
						},
						{
							text:        'Användare',
							to:          '/settings/users',
							rightsCheck: () => this.UserRights.hasUsersReadAccess({Roll, FtgInfo: this.FtgInfo}),
						},
						{
							text:        'Behörighetsgrupper',
							to:          '/settings/userRightsGroups',
							rightsCheck: () => this.UserRights.hasUsersRightsGroupsReadAccess({Roll, HasPlus}),
						},
						{
							text:        'Kortnummer',
							to:          '/settings/kortnummer',
							rightsCheck: () => this.UserRights.hasKortnummerReadAccess(),
						},
						{
							text:        'Kopplingar',
							to:          '/settings/integrations',
							rightsCheck: () => this.UserRights.hasIntegrationsReadAccess({Roll}),
						},
						{
							text:        'Myndighetsuppgifter',
							to:          '/settings/sysdata',
							rightsCheck: () => this.UserRights.hasSysDataReadAccess(),
						},
						{
							text:        'Digital brevlåda',
							to:          '/settings/digitalmailbox',
							rightsCheck: () => this.UserRights.hasDigitalMailboxSettingsReadAccess(),
						},
						{
							text:        'Utskrifter',
							to:          '/settings/printouts',
							rightsCheck: () => this.UserRights.hasPrintoutsReadAccess(),
						},
						{
							text:        'Export',
							to:          '/settings/export',
							rightsCheck: () => this.UserRights.hasExportSettingsReadAccess(),
						},
					],
				},
			].filter((item) => {
				if(item.rightsCheck && !item.rightsCheck()) {
					return false;
				}

				if(item.children) {
					item.children = item.children.filter((item) => item.rightsCheck ? item.rightsCheck() : true);

					return item.children.length;
				}

				if(item.to || item.onClick) {
					return true;
				}

				return false;
			});

			this.$router.checkRight(this.$route, '/infopanel')
		},
	},

	created() {
		this.setNavigationItems();

		this.$eventBus.$on('toggleNavigation', () => {
			if(this.$vuetify.breakpoint.lgAndUp) {
				this.mini = !this.mini;
			} else {
				this.drawer = !this.drawer
			}
		});

		this.$eventBus.$on('setNavigationItems', () => {
			this.setNavigationItems();
		});
	},

	computed: {
		...mapState(['FtgInfo', 'HasPlus', 'UserAccount', 'UserRights', 'HasLicensOption', 'LicenseSupplier', 'AgreementsList']),

		canSelectCompany() {
			return this.UserAccount.Roll === 'LIC' ||
				this.UserAccount.Roll === 'ADM' ||
				this.UserAccount.FtgIdAccessList.length > 1;
		},

		isMini() {
			return this.mini && this.$vuetify.breakpoint.lgAndUp;
		},

		// mini() {
		// 	return this.$vuetify.breakpoint.lgAndUp;
		// }
	},
}
</script>

<style scoped>
	::v-deep(.v-list__tile) {
		padding-left: 5px;
		padding-right: 5px;
	}

	/*Removes borders around images/icons when in mini mode*/
	::v-deep(.listgroupmini:after, .listgroupmini:before) {
		content: none;
	}

	::v-deep(.v-list-group__header) {
		padding: 0 !important;
	}

	::v-deep(.is-expanded .v-avatar) {
		margin-right: 8px !important;
	}

	::v-deep(.is-expanded .v-list-group__items .v-list-item) {
		padding: 0 !important;
	}

	::v-deep(.reduced-min-height) {
		min-height: 20px;
	}

	::v-deep(.theme--dark .v-image__image) {
		filter: brightness(110%);
	}
</style>
