<template>
	<Dialog ref="dialog" title="Skicka kopia av företag" :persistent="isSending" :width="800">

		<v-container fluid>
			<v-row dense justify="center">
				<v-col cols="12">
					<p class="font-weight-bold">Denna funktion ska endast användas i samråd med supporten!</p>
					<p>
						Under tiden du skapar och skickar kopian kan du inte arbeta i programmet och vissa delar av företaget kan även vara oåtkomliga för t.ex. användare i Crona Portal.
					</p>
					<p>
						För att använda tjänsten måste personuppgiftsbiträdesavtal godkännas, då vår personal kommer att ha tillgång till företagets personuppgifter. Läs avtalet som finns länkat innan du godkänner det genom att kryssa i rutan nedan. När supportärendet är avslutat kommer supporten att ta bort kopian vi fått tillgång till.
					</p>
					<p>
						<v-btn color="primary" depressed :href="agreementLink" target="_blank" small>Visa avtal <v-icon right>mdi-open-in-new</v-icon></v-btn>
					</p>
				</v-col>
				<v-col cols="12">
					<SimpleCheckbox :disabled="isSending" v-model="SendApproval" label="Jag vill skicka kopian och jag godkänner avtalet" dense hide-details class="mb-5"/>

					<SimpleTextInput :disabled="isSending" v-model="OptionalInfoText" label="Frivillig infotext" dense hide-details :counter="100" class="mt-5" outlined/>
				</v-col>
				<v-fade-transition hide-on-leave>
					<v-col cols="12" v-if="isSending" class="pt-5">
						{{statusMessage}}
						<v-progress-linear indeterminate height="15"/>
					</v-col>
				</v-fade-transition>

				<v-fade-transition hide-on-leave>
					<v-col cols="12" v-if="showLargeCompaniesText" class="pt-3">
						<v-alert border="top" colored-border type="info" elevation="1" class="caption" dense dismissible>
							Större företag kan ta lite längre tid, vänligen avbryt inte.
						</v-alert>
					</v-col>
				</v-fade-transition>
			</v-row>
		</v-container>

		<template v-slot:buttons="{hide}">
			<v-btn color="secondary" @click="hide" :disabled="isSending">Avbryt</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="onSend" :loading="isSending" :disabled="!SendApproval">Skicka</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog          from './Dialog.vue';
import Title           from './typography/Title.vue';
import SimpleCheckbox  from './SimpleCheckbox.vue';
import SimpleTextInput from './SimpleTextInput.vue';
export default {
	name: 'SendCompanyCopyDialog',

	data: () => ({
		SendApproval:           false,
		OptionalInfoText:       '',
		isSending:              false,
		showLargeCompaniesText: false,
		agreementLink:          'https://fsdl.crona.se/Dokument/Avtal/Personuppgiftsbitradesavtal.pdf',
		statusMessage:          '',
	}),

	methods: {
		show() {
			this.statusMessage = '';
			this.SendApproval       = false;
			this.$refs.dialog.show();
		},

		onSend() {
			this.statusMessage = '';

			this.$tryCatch({
				task: async () => {
					this.isSending = true;

					const {Id} = await this.$http.post('/sendCompanyCopy', {
						InfoText: this.OptionalInfoText,
					});

					setTimeout(() => {
						this.showLargeCompaniesText = this.isSending;
					}, 30000);

					await new Promise((resolve, reject) => {
						const fetchStatus = async () => {
							const data = await this.$http.get(`/sendCompanyCopy/status/${encodeURIComponent(Id)}`);

							if(!data || data.Status === 'FAILED') {
								reject(new Error('Job Failed'));
								
								return;
							}

							switch (data.Status) {
								case 'COMPLETED': {
									this.$msgSuccess('En kopia av företaget har skickats till Crona Support!');
									resolve();
							
									break;
								}
								case 'INPROGRESS': {
									this.statusMessage = 'Skickar kopia av företag till Crona Support...';
									setTimeout(fetchStatus, 5000);
							
									break;
								}
								case 'QUEUED': {
									this.statusMessage = 'Din begäran ligger i kö, vänligen vänta...';
									setTimeout(fetchStatus, 2500);
							
									break;
								}
							}
						};

						fetchStatus();
					});
				},

				error: () => {
					this.$msgError('Misslyckades att skicka en kopia av företaget till Crona Support. Vänligen försök igen lite senare.');
				},

				finally: () => {
					this.isSending = false;
					this.$refs.dialog.hide();
				},
			});
		},
	},

	components: {SimpleTextInput, SimpleCheckbox, Title, Dialog},
}
</script>

<style scoped>

</style>
