<template>

	<v-snackbar
					v-model="Snackbar.visible"
					:color="Snackbar.color"
					:bottom="Snackbar.position !== 'top'"
					:top="Snackbar.position === 'top'"
					:right="Snackbar.right"
					:left="Snackbar.left"
					:multi-line="Snackbar.multiline"
					:timeout="Snackbar.timeout"
	>
		<v-row dense  class="pl-3">
			<v-progress-linear
							:color="Snackbar.color"
							class="darken-3"
							:class="Snackbar.color"
							style="position: absolute; top: 0;left: 0;"
							:height="5"
							:value="progressValue"
							reverse
			/>
			<v-col cols="12" v-if="Snackbar.title">
				<h2 class="subtitle-2">{{Snackbar.title}}</h2>
			</v-col>

			<v-col cols="12" v-html="Snackbar.text">
			</v-col>
		</v-row>

		<template v-slot:action="{}">
			<v-btn
							dark
							text
							@click="Snackbar.visible = false"
							icon
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>

	</v-snackbar>
</template>

<script>
import {mapState} from 'vuex';

export default {
	name: 'AppSnackbar',

	computed: {
		...mapState(['Snackbar']),

		now() {
			return new Date();
		},
	},

	data: () => ({
		progressValue: 0,
	}),

	watch: {
		'Snackbar.visible'() {
			clearTimeout(this.progressTimer);

			if(!this.Snackbar.visible) {
				return;
			}

			this.progressValue = 0;

			let ticks = this.Snackbar.timeout / 100;
			const prcPerTick = 100 / ticks;

			this.progressTimer = setInterval(() => {
				ticks--;
				this.progressValue += prcPerTick;

				if(ticks === 0) {
					clearTimeout(this.progressTimer);
				}
			}, 100);
		},
	},
}
</script>

<style>
</style>
