/**
 * Created by simon on 2019-12-17.
 */

import _      from 'lodash';
import moment from 'moment';
import luhn   from './luhn.js';

const isValidPersNr = (persNr, allowSamordningsnummer = false) => {
	if(!(/^\d{6}(\d{2})?[+-]?\d{4}$/).test(persNr)) {
		return false;
	}

	const val = _.toString(persNr).replace(/[+-]/, '');

	if(!luhn(val)) {
		return false;
	}

	//Check for valid date
	const YYYY = _.toNumber(val.slice(0, 4));
	const MM   = _.toNumber(val.slice(4, 6)) - 1;
	let DD     = _.toNumber(val.slice(6, 8));

	//Samordningsnummer, example 196206703974
	if(DD > 31 && allowSamordningsnummer) {
		DD -= 60;
	}

	const date  = new Date(YYYY, MM, DD);
	const mDate = moment(date);

	return date && mDate.year() === YYYY && mDate.month() === MM && mDate.date() === DD;
};

export default isValidPersNr;
