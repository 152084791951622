<template>
	<div>
		<v-list two-line>
			<v-subheader v-if="title">{{title}}</v-subheader>
			<template v-for="item in items">
				<v-list-item
					:key="item.FtgId"
					@click="$emit('click', item)"
				>
					<v-list-item-avatar>
						<CompanyTableAvatar :item="item" :tile="true" :canEdit="false" size-no-image="42px"/>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>
							{{item.Namn}}
						</v-list-item-title>
						<v-list-item-subtitle>{{$formatter.formatPersNrOrgNrTo12(item.Orgnr, {dash: true, serialNr: false}).slice(2)}} - {{item.FtgId}}</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<slot name="action" v-bind="{item}">
							<v-row dense>
								<v-col v-if="cloneCompanyEnabled && !item.Archived">
									<Tooltip text="Kopiera företag">
										<v-btn icon color="accent" @click.stop.prevent="onShowCopyFtgDialog(item.FtgId)"><v-icon>mdi-content-copy</v-icon></v-btn>
									</Tooltip>
								</v-col>
								<v-col v-if="HasPlus && ['LIC', 'ADM'].includes(UserAccount.Roll)">
									<Tooltip :text="item.Archived ? 'Återför företag från arkiv' : 'Arkivera företag'">
										<v-btn icon text color="accent" @click.stop.prevent="onToggleArchive(item)"><v-icon>{{item.Archived ? 'mdi-archive-cancel' : 'mdi-archive'}}</v-icon></v-btn>
									</Tooltip>
								</v-col>
								<v-col v-if="showFavourite">
									<Tooltip :text="item.isFavourite ? 'Avmarkera favorit' : 'Markera favorit'">
										<v-btn icon text :color="item.isFavourite ? 'accent' : 'secondary'" @click.stop="toggleFavourite(item)"><v-icon>{{item.isFavourite ? 'mdi-star' : 'mdi-star-outline'}}</v-icon></v-btn>
									</Tooltip>
								</v-col>
							</v-row>
						</slot>
					</v-list-item-action>
				</v-list-item>
			</template>
		</v-list>

		<Dialog v-if="cloneCompanyEnabled" ref="copyDialog" title="Kopiera företag" :persistent="isCopying">
			<v-container fluid>
				<v-row dense>
					<v-col cols="12">
						<SimpleTextInput v-model="targetFtgId" label="Nytt företags-id" :counter="15"/>
					</v-col>
					<v-col cols="12">
						<v-alert border="top" colored-border type="info" elevation="2" dense class="caption">
							Observera att följande inställningar <b>inte</b> kopieras till det nya företaget:
							<ul>
								<li>Standard behörighetsgrupp för företaget</li>
								<li>Användarkonton</li>
								<li>Åtkomst för företagsadministratörer</li>
							</ul>
						</v-alert>
					</v-col>
					<v-fade-transition hide-on-leave>
						<v-col cols="12" v-if="isCopying" class="pt-5">
							{{copyStatusMessage}}
							<v-progress-linear indeterminate height="15"/>
						</v-col>
					</v-fade-transition>
				</v-row>
			</v-container>

			<template v-slot:buttons="{hide}">
				<v-btn color="secondary" @click="hide" :disabled="isCopying">Avbryt</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="onCopy" :loading="isCopying">Kopiera</v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
import _                      from 'lodash';
import {mapActions, mapState} from 'vuex';
import LocalStorage           from '../lib/LocalStorage.js';
import Tooltip                from './Tooltip.vue';
import CompanyTableAvatar     from './CompanySettings/CompanyTableAvatar.vue';
import Dialog                 from './Dialog.vue';
import SimpleTextInput        from './SimpleTextInput.vue';

export default {
	name: 'SelectCompanyList',

	props: {
		items: {
			type:     Array,
			required: true,
		},

		title: {
			type:    String,
			default: '',
		},

		showFavourite: {
			type:    Boolean,
			default: true,
		},
	},
	
	data: () => ({
		originalFtgId:     '',
		targetFtgId:       '',
		isCopying:         false,
		copyStatusMessage: '',
	}),

	components: {
		SimpleTextInput,
		Dialog,
		CompanyTableAvatar,
		Tooltip,
	},

	methods: {
		...mapActions(['confirmDialog']),

		onToggleArchive(item) {
			const {Archived, Namn, FtgId, Orgnr} = item;

			this.$tryCatch({
				task: async () => {
					if(!Archived) {
						await new Promise((resolve, reject) => {
							this.confirmDialog({
								title: 'Arkivera företag',
								text:  [
									`Är du säker på att du vill arkivera företaget <b>${this.$formatter.escape(Namn || FtgId || Orgnr)}</b>?`,
									'Arkivering döljer endast företaget från den primära listan av valbara företag.',
									'Du kommer fortfarande kunna komma åt företaget och alla eventuella användare som är direkt kopplade till företaget kommer fortfarande kunna logga in och registrera underlag.',
									'Ansvaret faller på dig som administratör att även inaktivera användarkonton och integrationer kopplade till företaget om så önskas.',
								].join('<br/><br/>'),
								cancelText:   'Avbryt',
								confirmColor: 'error',
								confirmText:  'Arkivera',
								onCancel:     () => reject(new Error('UserCancel')),
								onConfirm:    () => resolve(),
							})
						});
					}

					await this.$http.delete(`/companyInfo/${encodeURIComponent(FtgId)}/toggleArchivedState`);

					this.$msgSuccess(Archived ? 'Företaget återfördes från arkiv' : 'Företaget arkiverades.');
					this.$emit('reload');
				},
			});
		},

		onShowCopyFtgDialog(FtgId) {
			this.originalFtgId = FtgId;

			const lastCharacterInFtgId = [...FtgId].pop();
			
			FtgId = isNaN(parseInt(lastCharacterInFtgId, 10)) ? `${FtgId}2` : `${FtgId.slice(0, - 1)}${parseInt(lastCharacterInFtgId, 10) + 1}`;

			this.targetFtgId = `${FtgId}`;
			this.$refs.copyDialog.show();
		},

		onCopy() {
			if(!this.targetFtgId) {
				this.$msgError('Du måste ange ett nytt företags-id.');

				return;
			}

			this.targetFtgId = this.targetFtgId.replace(/[^\d+a-zäåö]/gi, '').replace(/å|ä/gi, 'a').replace(/ö/gi, 'o').toLowerCase();

			if(this.items.find(({FtgId}) => FtgId === this.targetFtgId)) {
				this.$msgError('Det finns redan ett företag med samma id.');

				return;
			}

			if(this.targetFtgId.length > 15) {
				this.$msgError('Företags-id får inte vara längre än 15 tecken.');

				return;
			}

			this.$tryCatch({
				task: async () => {
					this.isCopying = true;

					const {Id} = await this.$http.post('/cloneCompany', {originalFtgId: this.originalFtgId, targetFtgId: this.targetFtgId});

					await new Promise((resolve, reject) => {
						const fetchStatus = async () => {
							const data = await this.$http.get(`/cloneCompany/status/${encodeURIComponent(Id)}`);

							if(!data || data.Status === 'FAILED') {
								reject(new Error('Job Failed'));

								return;
							}

							switch (data.Status) {
								case 'COMPLETED': {
									this.$msgSuccess('Företaget kopierades.');
									this.$refs.copyDialog.hide();
									this.targetFtgId = '';
									this.$emit('reload');
									resolve();

									break;
								}
								case 'INPROGRESS': {
									this.copyStatusMessage = 'Kopierar företaget, vänligen vänta...';
									setTimeout(fetchStatus, 5000);

									break;
								}
								case 'QUEUED': {
									this.copyStatusMessage = 'Din begäran ligger i kö, vänligen vänta...';
									setTimeout(fetchStatus, 2500);

									break;
								}
							}
						};

						fetchStatus();
					});
				},

				finally: () => {
					this.isCopying = false;
				},
			});
		},
		
		toggleFavourite(item) {
			const {FtgId} = item;
			const favourites = LocalStorage.get('FavouriteCompanies') || [];

			if(_.includes(favourites, FtgId)) {
				LocalStorage.set('FavouriteCompanies', _.filter(favourites, (v) => v !== FtgId));
				item.isFavourite = false;
			} else {
				favourites.push(FtgId);
				LocalStorage.set('FavouriteCompanies', favourites);
				item.isFavourite = true;
			}

			return false;
		},

		getNamnInitials(item) {
			const {Namn, FtgId} = item;

			if(Namn) {
				const first = Namn.slice(0, 1);
				const second = ([...Namn.slice(1)].find((value) => value === value.toUpperCase()) || '');

				return [first.toUpperCase(), second.toUpperCase()].join('');
			}

			return FtgId.slice(0, 2).toUpperCase();
		},
	},

	computed: {
		...mapState(['UserAccount', 'HasPlus']),

		cloneCompanyEnabled() {
			return this.UserAccount.Roll === 'LIC' && (process.env.VUE_APP_CLONE_COMPANY_WHITELIST || '').split(',').map((LicNr) => LicNr.trim()).includes(this.UserAccount.LicNr);
		},
	},
}
</script>

<style scoped>

</style>
