<template>
	<span>
		<v-tooltip v-bind="tooltipDirection" v-if="text">
			<template v-slot:activator="{on}">
			<span v-on="on">
				<slot></slot>
			</span>
			</template>
			<span v-if="allowHTML" v-html="text"></span>
			<span v-else v-html="escapedText"></span>
		</v-tooltip>
		<slot v-if="!text"></slot>
	</span>
</template>

<script>
import {escape} from 'lodash';

export default {
	name:  'Tooltip',
	props: {
		text: {
			type:     String,
			required: true,
		},
		direction: {
			type:    String,
			default: 'bottom',
		},

		allowHTML: {
			type:    Boolean,
			default: false,
		},
	},

	computed: {
		tooltipDirection() {
			const direction = this.direction.toLowerCase();

			return {
				bottom: direction === 'bottom',
				top:    direction === 'top',
				left:   direction === 'left',
				right:  direction === 'right',
			};
		},

		escapedText() {
			return escape(this.text).replace(/\n/g, '<br/>');
		},
	},
}
</script>

<style scoped>

</style>
