/**
 * Created by simon on 2018-12-13.
 */

import {map, split, find, findIndex, last, get, toLower, isObject} from 'lodash';

const TabOrder = {
	install(Vue) {
		Vue.mixin({
			methods: {
				$checkForTabOrderEvent(event) {
					const isEnter     = event && event.keyCode === 13;
					const isTab       = event && event.keyCode === 9;
					const isBackwards = event && event.shiftKey;
					const name        = event && event.target && event.target.attributes && event.target.attributes['data-tabordername'] && event.target.attributes['data-tabordername'].value;

					if((isEnter || isTab) && name) {
						return !!this.$tabOrderToNextField(name, isBackwards ? -1 : 1, event, isTab ? 'Tab' : 'Enter');
					}

					return false;
				},

				$tabOrderToNextField(name, direction = 1, event = null, type = 'Enter') {
					type = type || (event && event.keyCode === 9 ? 'Tab' : 'Enter');

					if(name) {
						const isBackwards = direction === -1;
						const parentContainingTabOrder = findParentContainingTabOrder(this);

						if(parentContainingTabOrder && 'function' === typeof parentContainingTabOrder.customTabOrderHandler) {
							return parentContainingTabOrder.customTabOrderHandler(name, direction, event, type);
						}

						if(parentContainingTabOrder && !parentContainingTabOrder.tabOrder.$_tabOrderMutatedToHandleNestedComponents) {
							parentContainingTabOrder.tabOrder = map(parentContainingTabOrder.tabOrder, (value, index) => {
								const tabKeyDisabled   = isObject(value) && value.tabKeyDisabled === true;
								const enterKeyDisabled = isObject(value) && value.enterKeyDisabled === true;

								value = isObject(value) ? value.field : value;

								const pathParts = split(value, '.');

								const path = pathParts.length > 1
									? `${pathParts.shift()}.[0].$refs.${pathParts.join('.$refs.')}`
									: pathParts.shift();

								return {
									name: last(split(value, '.')),
									index,
									path,
									tabKeyDisabled,
									enterKeyDisabled,
								};
							});

							parentContainingTabOrder.tabOrder.$_tabOrderMutatedToHandleNestedComponents = true;
						}

						if(
							parentContainingTabOrder &&
							parentContainingTabOrder.tabOrder &&
							parentContainingTabOrder.tabOrder.length > 0 &&
							find(parentContainingTabOrder.tabOrder, (item) => item.name === name)
						) {
							const nextTarget = getNextTarget(parentContainingTabOrder, name, isBackwards, type);

							if(nextTarget) {
								//Closes date/time picker on next target focus
								if(this.menu) {
									this.menu = false;
								}

								if(event) {
									event.preventDefault();
									event.stopPropagation();
								}

								setTimeout(() => {
									nextTarget.focus();

									if(nextTarget.$el) {
										nextTarget.$el.scrollIntoView({
											block: 'nearest',
										});
									} else if(nextTarget.$refs && nextTarget.$refs.input) {
										nextTarget.$refs.input.scrollIntoView({
											block: 'nearest',
										});
									}
								}, 0);

								return nextTarget;
							}
						}
					}

					return null;
				},
			},
		});
	},
};

function getNextTarget(context, name, isBackwards = false, type, attempts = 0) {
	if(attempts > context.tabOrder.length) {
		return context.$refs[context.tabOrder[0].path]; //No match, go back to first field
	}

	const disabledKeyFieldCheck = `${toLower(type)}KeyDisabled`;

	let nextIndex = findIndex(context.tabOrder, {name}) + (isBackwards ? -1 : 1);

	if(!context.tabOrder[nextIndex]) {
		nextIndex = isBackwards ? context.tabOrder.length - 1 : 0;
	}

	const nextTarget = context.tabOrder[nextIndex];

	let nextTargetComponent = get(context.$refs, nextTarget.path) || get(context.$refs, nextTarget.path.replace('[0].', ''));

	if(nextTargetComponent && get(nextTargetComponent, '$el.nodeName', null) === '#comment') {
		nextTargetComponent = null;
	}

	if(nextTarget !== name && !nextTarget[disabledKeyFieldCheck] && (nextTargetComponent || context.getInputComponentForTabOrder)) {
		if(!nextTargetComponent && context.getInputComponentForTabOrder) {
			nextTargetComponent = context.getInputComponentForTabOrder(nextTarget.name);

			//Not rendered/comment (NodeType 8 = Comment)
			if(nextTargetComponent && nextTargetComponent.$el && nextTargetComponent.$el.nodeType === 8) {
				nextTargetComponent = null;
			}
		}

		if(nextTargetComponent && nextTargetComponent.getInputComponentForTabOrder) {
			nextTargetComponent = nextTargetComponent.getInputComponentForTabOrder(nextTarget.name);
		}


		if(Array.isArray(nextTargetComponent)) {
			nextTargetComponent = nextTargetComponent[0];
		}

		if(
			!nextTargetComponent || //Does not exist
			( //Disabled field
				nextTargetComponent.$refs.field &&
				nextTargetComponent.$refs.field.disabled
			)			||
			( //Not visible (such as ToggleCard closed)
				(
					nextTargetComponent.$refs.field &&
					nextTargetComponent.$refs.field.$el.offsetParent === null
				)				||
				(
					nextTargetComponent.$el &&
					nextTargetComponent.$el.offsetParent === null
				)				||
				(
					nextTargetComponent.$el &&
					nextTargetComponent.$el.disabled
				)
			)
		) {
			//Check if field is enabled, but hidden behind a ToggleCard, and if so, auto-open the ToggleCard.
			if(
				nextTargetComponent &&
				nextTargetComponent.$refs.field &&
				!nextTargetComponent.$refs.field.disabled &&
				nextTargetComponent.$refs.field.$el &&
				nextTargetComponent.$refs.field.$el.offsetParent === null
			) {
				let parent = nextTargetComponent.$refs.field.$parent;
				for(let i = 0; i < 5; i++) {
					if(parent.$options && parent.$options._componentTag === 'ToggleCard') {
						parent.open();

						return nextTargetComponent;
					}
					parent = parent.$parent;
				}
			}

			return getNextTarget(context, nextTarget.name, isBackwards, type, attempts + 1);
		}

		if(nextTargetComponent.focus) {
			return nextTargetComponent;
		} else if(nextTargetComponent.$el && nextTargetComponent.$el.focus) {
			return nextTargetComponent.$el;
		} 
		
		return null;
	}

	return getNextTarget(context, nextTarget.name, isBackwards, type, attempts + 1);
}

function findParentContainingTabOrder(context, attempts = 0) {
	if(attempts > 20) {
		return null;
	}

	if(context && (context.tabOrder || 'function' === typeof context.customTabOrderHandler)) {
		return context;
	}

	return context ? findParentContainingTabOrder(context.$parent, attempts) : null;
}

export default TabOrder;
