/**
 * Created by simon on 2019-11-25.
 */

import {forEach} from 'lodash';
import store     from '../store/index.js';

const sharedViewFilters = {};

forEach(store.state.SharedViewFilters, (v, key) => {
	Object.defineProperty(sharedViewFilters, key, {
		get() {
			return store.state.SharedViewFilters[key];
		},

		set(value) {
			if(key === 'dateFilter' && store.state.SharedViewFilters.dateFilter.Locked) {
				return;
			}
			if(key === 'dateFilter') { //If key changed is dateFilter (AS AN OBJECT) then Lock values (From DatePickerRange)
				value.Locked = true;
			} else if(key === 'selectedSalaryPeriod' && value !== null) { //When selecting a new salaryperiod, unlock dateFilter
				store.state.SharedViewFilters.dateFilter.Locked = false;
			}

			if(key === 'selectedEmployee' && store.state.SharedViewFilters.payslipSelectedEmployee) {
				if(store.state.SharedViewFilters.payslipSelectedEmployee) {
					store.state.SharedViewFilters.payslipSelectedEmployee = value;
				}

				store.dispatch('setSharedViewFiltersTabellKodFromEmployee');
			} else if(key === 'payslipSelectedEmployee' && value) {
				store.state.SharedViewFilters.selectedEmployee = value;
				store.dispatch('setSharedViewFiltersTabellKodFromEmployee');
			}

			store.state.SharedViewFilters[key] = value;

			//If dateFilter has been locked then automatically remove salaryperiod selection
			if(store.state.SharedViewFilters.dateFilter.Locked) {
				store.state.SharedViewFilters.selectedSalaryPeriod = null;
			}
		},
	});
});

export default sharedViewFilters;
