<template>
	<div>
		<v-menu v-if="buttons.length > menuButtonCountBreakpoint" left top>
			<template v-slot:activator="{on}">
				<v-btn
						color="primary"
						v-on="on"
						:disabled="anyButtonEnabled !== true"
						:loading="isLoading"
				>
					<v-icon left>mdi-format-list-bulleted</v-icon> {{menuButtonText}} <v-icon right>mdi-menu-down</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item
						v-for="(button, index) in buttons"
						:key="index"
				>
					<v-list-item-title>
						<slot name="button" v-bind="button"></slot>
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<template v-else v-for="(button) in buttons">
			<slot name="button" v-bind="button"></slot>
		</template>
	</div>
</template>

<script>
export default {
	name: 'MassButtonsHandler',

	props: {
		menuButtonText: {
			type:     String,
			required: true,
		},

		buttons: {
			type:     Array,
			required: true,
		},

		anyButtonEnabled: {
			type:    Boolean,
			default: false,
		},

		menuButtonCountBreakpoint: {
			type:    Number,
			default: 4,
		},

		isLoading: {
			type:    Boolean,
			default: false,
		},
	},
}
</script>

<style scoped>
::v-deep(button) {
	margin-left: 5px;
}

::v-deep(.v-list-item__title > button) {
	width: 100%;
}

::v-deep(.v-list-item__title > button > span) {
	padding: 0;
	justify-content: left;
}
</style>
