/**
 * Created by simon on 2019-12-17.
 */

import _    from 'lodash';
import luhn from './luhn.js';

const isValidOrgNr = (orgNr) => {
	orgNr = _.toString(orgNr).replace(/\D+/g, '');

	if(orgNr.length !== 12) {
		return false;
	}

	if(!_.includes([16, 18, 19, 20], _.toNumber(orgNr.slice(0, 2)))) {
		return false;
	}

	return luhn(orgNr);
};

export default isValidOrgNr;
