/**
 * Created by simon on 2018-11-30.
 */

import Validate  from '../lib/Validate.js';
import BaseModel from './BaseModel.js';

class Login extends BaseModel {
	static get propertyMap() {
		return {
			Login: {
				label:        'Användarnamn',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.validLogin,
				],
			},
			Password: {
				label:        'Lösenord',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.validPassword,
				],
			},

			SMSCode: {
				label:        'Verifieringskod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
				],
			},
		};
	}
}

export default Login;
