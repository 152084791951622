<template>
	<v-card outlined v-bind="$attrs">
		<v-card-title @click="visible_ = !visible_" style="cursor: pointer" :class="{'pb-0': visible_, 'pa-2': dense}">
			<v-icon>{{visible_ ? $vuetify.icons.values.expand : $vuetify.icons.values.next}}</v-icon>
			<Title :small="smallTitle" black v-if="title">{{title}}</Title>
			<slot name="title" v-bind="{visible: visible_}"></slot>
			<slot name="badge" v-bind="{visible: visible_}"></slot>
		</v-card-title>
		<template v-if="lazyLoad">
			<v-lazy transition="none">
				<v-card-text v-show="visible_" :class="{'pt-5': useCardPadding && !noPadding, 'pa-0': noPadding}">
					<slot></slot>
				</v-card-text>
			</v-lazy>
		</template>

		<v-card-text v-else v-show="visible_" :class="{'pt-5': useCardPadding && !noPadding, 'pa-0': noPadding}">
			<slot></slot>
		</v-card-text>
		<slot name="actions"></slot>
	</v-card>
</template>

<script>
import Title from './typography/Title.vue';

export default {
	name: 'ToggleCard',

	props: {
		title: {
			type: String,
		},
		visible: {
			default: true,
		},
		dense: {
			type:    Boolean,
			default: false,
		},
		helpTextId: {
			type:    String,
			default: '',
		},

		useCardPadding: {
			type:    Boolean,
			default: true,
		},

		noPadding: {
			type:    Boolean,
			default: false,
		},

		smallTitle: {
			type:    Boolean,
			default: false,
		},

		lazyLoad: {
			type:    Boolean,
			default: false,
		},
	},

	data: () => ({
		visible_: true,
	}),

	mounted() {
		this.visible_ = this.visible;
	},

	watch: {
		visible(value) {
			this.visible_ = value;
		},

		visible_() {
			this.$emit('input', this.visible_);

			const event = this.visible_ ? 'open' : 'close';

			this.$emit(event);

			if(this.visible_ && this.helpTextId) {
				this.$eventBus.$emit('Help:SetFromHelpTextId', {
					id:    this.helpTextId,
					title: this.title,
				});
			}
		},
	},

	methods: {
		isVisible() {
			return this.visible_;
		},

		open() {
			this.visible_ = true;
			this.$emit('open');
		},

		close() {
			this.visible_ = false;
			this.$emit('close');
		},
	},

	components: {
		Title,
	},
}
</script>

<style scoped>

</style>
