<template>
	<Dialog
					:title="Title"
					fullscreen
					ref="dialog"
	>
		<v-row dense justify="center" class="text-center">
			<v-col cols="8">
				<v-row dense class="mb-6">
					<v-col cols="12">
						<Title>{{ Title }}</Title>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<Title bold>Beräknade värden</Title>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Vid ett årsskifte rekommenderar vi att du låter programmet räkna ut belopp för beräknad årslön, snittlön, och snittid under det nya löneårets gång.
						</blockquote>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Finns det anställda med avvikande värden kommer det att släckas för det nya löneåret för respektive ruta som markeras nedan.
						</blockquote>


						<SimpleCheckbox
										class="mt-5 d-inline-flex mr-5 ml-5"
										ref="CalcBerArsLon"
										name="CalcBerArsLon"
										label="Beräkna årslön automatiskt"
										dense
										v-model="data.CalcBerArsLon"
										persistent-hint
										hint="Beräknas baserat på löneuppgifter under året"
						/>
						<SimpleCheckbox
										class="mt-5 d-inline-flex mr-5 ml-5"
										ref="CalcBerSnittLon"
										name="CalcBerSnittLon"
										label="Beräkna snittlön automatiskt"
										dense
										v-model="data.CalcBerSnittLon"
										persistent-hint
										hint="Beräknas baserat på löneuppgifter under året"
						/>
						<SimpleCheckbox
										class="mt-5 d-inline-flex mr-5 ml-5"
										ref="CalcBerSnittTid"
										name="CalcBerSnittTid"
										label="Beräkna snittid automatiskt"
										dense
										v-model="data.CalcBerSnittTid"
										persistent-hint
										hint="Beräknas baserat på arbetstid under året"
						/>

					</v-col>

					<v-col cols="12" class="mt-5 mb-5">
						<v-divider/>
					</v-col>

					<v-col cols="12" >
						<Title bold>Löneperioder</Title>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Programmet skapar automatiskt nya löneperioder vid årsskiftet baserat på tidigare använda mallar.
						</blockquote>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Om du vill skapa löneperioderna själv för det nya löneåret vid ett senare tillfälle så kan du avmarkera alternativet här.
						</blockquote>

						<SimpleCheckbox
										class="mt-5 d-inline-flex mr-0"
										ref="CreateSalaryPeriods"
										name="CreateSalaryPeriods"
										label="Skapa löneperioder automatiskt"
										dense
										hide-details
										v-model="data.CreateSalaryPeriods"
						/>
					</v-col>

					<v-col cols="12" class="mt-5 mb-5">
						<v-divider/>
					</v-col>

					<v-col cols="12">
						<Title bold>Löneartstabeller</Title>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Programmet kopierar automatiskt över alla löneartstabeller från {{SalaryYear}} till det nya löneåret.
						</blockquote>
<!--						<blockquote class="blockquote font-weight-light ma-0 pa-0">-->
<!--							Om du vill skapa löneartstabeller själv för det nya löneåret så kan du markera det här.-->
<!--						</blockquote>-->

						<SimpleCheckbox
										class="mt-5 d-inline-flex mr-0"
										ref="CopyLonTabell"
										name="CopyLonTabell"
										label="Kopiera lönearter automatiskt"
										dense
										hide-details
										v-model="data.CopyLonTabell"
										disabled
						/>
					</v-col>

					<v-col cols="12" class="mt-5 mb-5">
						<v-divider/>
					</v-col>

					<v-col cols="12">
						<Title bold>Bilförmån</Title>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Programmet kan i samband med det nya året automatiskt beräkna nya värden för bilförmån för de anställda.
						</blockquote>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Om du vill hantera detta själv för det nya löneåret så kan du avmarkera alternativet här.
						</blockquote>

						<SimpleCheckbox
							class="mt-5 d-inline-flex mr-0"
							ref="CalcVehicleBenefits"
							name="CalcVehicleBenefits"
							label="Beräkna nytt bilförmånsvärde automatiskt"
							v-model="data.CalcVehicleBenefits"
							@change="onChangeCalcVehicleBenefits"
							:disabled="!BilformanCalcEnabled"
							persistent-hint
							:hint="BilformanCalcEnabled ? '' : `Beskattningsår ${data.Year} är ännu inte öppet för beräkning hos Skatteverket.`"
						/>

						<RecalcVehicleBenefits v-if="data.CalcVehicleBenefits" ref="recalcVehicleBenefits" :showEmptyMsg="false"/>
					</v-col>

					<template v-if="data.Year === 2024 && UserRights.hasForaReadAccess(FtgInfo) && UserRights.hasAgreementsReadAccess()">
						<v-col cols="12" class="mt-5 mb-5">
							<v-divider/>
						</v-col>
					<v-col cols="12">
						<Title bold>Fora</Title>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							Fora inför månadsrapportering för arbetare vilket kan innebära att inställningar behöver ses över i löneprogrammet.
						</blockquote>
						<blockquote class="blockquote font-weight-light ma-0 pa-0">
							<v-btn class="mt-2" depressed small color="secondary" @click="$openNewTab('https://www.fora.se/manadsrapportering/fragor-och-svar/', {skipBaseURI: true})">Fora månadsrapportering - Frågor och svar <v-icon right>mdi-open-in-new</v-icon></v-btn>
						</blockquote>
					</v-col>
					</template>
				</v-row>
			</v-col>

		</v-row>

		<BottomToolbar full-width>
			<template slot="right">
				<v-btn color="secondary" @click="$refs.dialog.hide()">Avbryt</v-btn>
				<v-btn color="primary" @click="onCreate">Skapa</v-btn>
			</template>
		</BottomToolbar>
	</Dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Dialog                 from './Dialog.vue';
import BottomToolbar          from './BottomToolbar.vue';
import Title                  from './typography/Title.vue';
import RecalcVehicleBenefits  from './Personalregister/RecalcVehicleBenefits.vue';
import SimpleCheckbox         from './SimpleCheckbox.vue';

const getDefaultData = () => ({
	Year:                null,
	CalcVehicleBenefits: false,
	CopyLonTabell:       true,
	CreateSalaryPeriods: true,
	CalcBerArsLon:       true,
	CalcBerSnittLon:     true,
	CalcBerSnittTid:     true,
});

export default {
	name: 'CreateYearDialog',

	data: () => ({
		data: getDefaultData(),
		Year: null,
	}),

	methods: {
		...mapActions(['confirmDialog', 'addSalaryYear', 'setSalaryYear']),

		onCreate() {
			this.confirmDialog({
				title:        this.Title,
				text:         `Är du säker på att du vill skapa löneåret <b>${this.data.Year}</b> med angivna inställningar?`,
				cancelText:   'Avbryt',
				confirmText:  'Skapa',
				confirmColor: 'primary',
				onConfirm:    () => {
					this.$tryCatch({
						task: async () => {
							await this.$http.post('/createSalaryYear', this.data);
							this.addSalaryYear(this.data.Year);
							this.setSalaryYear({
								Year: this.data.Year,
							});
							this.$msgSuccess(`Löneår ${this.data.Year} har skapats.`);
							this.$refs.dialog.hide();
						},
					});
				},
			});
		},

		show() {
			this.data = getDefaultData();
			this.data.Year = parseInt(this.SalaryYears[0], 10) + 1;
			this.data.CalcVehicleBenefits = this.BilformanCalcEnabled;
			this.$refs.dialog.show();

			this.onChangeCalcVehicleBenefits(this.data.CalcVehicleBenefits);

			this.$eventBus.$emit('Help:SetFromHelpTextId', {
				id:    'createnewyeardialog',
				title: 'Skapa nytt löneår',
			})
		},

		onChangeCalcVehicleBenefits(value) {
			if(value === true) {
				this.$nextTick(() => {
					this.$refs.recalcVehicleBenefits.load(this.data.Year);
				});
			}
		},
	},

	computed: {
		...mapState(['SalaryYear', 'SalaryYears', 'UserRights', 'FtgInfo']),

		BilformanCalcEnabled() {
			return this.data.Year <= (new Date()).getFullYear();
		},

		Title() {
			return `Skapa löneår ${this.data.Year}`;
		},
	},

	components: {SimpleCheckbox, RecalcVehicleBenefits, Title, BottomToolbar, Dialog},
}
</script>

<style scoped>

</style>
